.colored-shape-custom {
  width: 15px;
  height: 15px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px #d8d8d8;
  position: relative;
  font-size: 12px;
  top: 3px;
  margin-right: 3px;
}

.title-style {
  max-width: 90%;
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-style-preview {
  font-size: 18px;
  display: inline-block;
}

.multi-select .dropdown-content {
  text-align: left;
}

.multi-select input[type="checkbox"] {
  display: none;
}

.anticon-close {
  position: relative;
  top: -3px;
  font-size: 15px !important;
}

.apply-button-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.apply-button-deep {
  font-size: 16px;
  margin-bottom: 5px !important;
  padding: 0px 40px !important;
}

.disabled-row {
  pointer-events: all;
}

.disabled-row span svg {
  display: block;
}

.default-row {
  pointer-events: all;
}

.default-row span svg {
  display: none;
}

.scroll-labels {
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.colors-dropdown {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  overflow: hidden;
}

.ant-row-padding-full {
  padding: 6px 10px;
  font-size: 16px;
  margin-bottom: 8px;
}

.vertical-line {
  border-left: 2px solid #1890ff;
  height: 15px;
  margin-left: 35px;
  margin-top: 4px;
}

.tag-base {
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 0 9px;
  width: 46%;
}

.tag-base span {
  /* width: 89px;
  text-overflow:ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;  */

  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  top: 3px;
  position: relative;
  padding: 3px 0;
}

.tag-base i {
  position: relative;
  top: -6px;
}

.tag-base-preview {
  font-size: 18px;
  border-radius: 10px;
  padding: 3px 10px;
}
.circle-picker-custom {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  text-align: center;
  padding: 0px 12px 0px 30px !important;
}
