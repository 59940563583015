
.circle-loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #70c381;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: circle-rotation 2s linear infinite;
}

.circle-loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-bottom: 4px solid #1892e1;
    border-left: 4px solid transparent;
}
  
@keyframes circle-rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
} 