/**
** Switch dashboard and sub components css.
** For Vispeahen.
**/

.switch-area {
    z-index: 1001;
    position: fixed;
    width: fit-content;
    height: 100dvh;
    min-height: 100dvh;
    top: 0;
    transition: all 300ms ease;
}

.switch-area.left {
    left: -50px;
}

.switch-area.right {
    right: -50px;
}

.switch-area.right.visible {
    right: 0
}

.switch-area.left.visible {
    left: 0;
}

.switch-dashboard {
    width: 45px;
    height: 100%;
    top: 0;
    background-color: #3a4044;
    transition: all 300ms ease-in;
    position: relative;
    border-right: 2px solid #3a4044;
    box-shadow: 1px 0px 4px 0px #3e3e3e;
    overflow-x: hidden;
    overflow-y: auto;
}

.switch-dashboard.expand {
    width: 216px;
}

.switch-visible-button {
    position: absolute;
    bottom: 40px;
    width: 35px;
    height: 35px;
    background-color: #3a4044;
    z-index: 1;
    border: 2px solid #3a4044;
    opacity: 0.7;
    transition: all 200ms ease-in-out;
}

.switch-area.right .switch-visible-button {
    left: -33px;
    border-right: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.switch-area.left .switch-visible-button {
    right: -33px;
    border-left: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.switch-visible-button:hover {
    cursor: pointer;
    opacity: 1;
}

.switch-visible-button .switch-icon {
    color: #ffffff;
    font-size: 16px;
    top: 0px;
    position: relative;
    left: 9px
}

.switch-area.right .switch-visible-button.expand {
    left: calc(50% - 17.5px) !important;
}

.switch-area.left .switch-visible-button.expand {
    right: calc(50% - 17.5px) !important;
}

.switch-area.visible .switch-visible-button {
    background: #ffffff22;
    z-index: 5;
    border-radius: 50%;
    transform: rotate(-180deg);
}

.switch-area.left.visible .switch-visible-button {
    right: 7px;
}

.switch-area.right.visible .switch-visible-button {
    left: 7px;
}


.switch-dashboard-title {
    color: #ffffff;
    width: 100%;
    height: 40px;
    padding: 10px;
}

.switch-dashboard-title .title-icon {
    position: absolute;
    font-size: 30px;
    top: 10px;
    left: 7px;
}

.switch-dashboard-title .title-content {
    margin-left: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
}

.switch-dashboard-label-list {
    width: 100%;
    padding: 0 9px;
    margin-top: 12px;
    opacity: 1;
    transition: all 300ms ease-in;
    display: none;
    position: relative;
    z-index: 5;
}

.switch-dashboard-label-list #switchDashboardLabelList {
    width: 100%;
    min-width: 27px;
    margin-top: 8px;
}

.switch-dashboard-label-list #switchDashboardLabelList .ant-select-arrow {
    right: 7px;
    color: rgb(210, 210, 210);
}

.switch-dashboard-label-list #switchDashboardLabelList .ant-select-selection {
    background: none;
    border-color: #2f3337;
    color: rgb(210, 210, 210);
}

.switch-dashboard-label-list2 {
    width: 100%;
    /* padding-left: 2px; */
    /* margin-top: 9px; */
    opacity: 1;
    transition: all 300ms ease-in;
    display: none;
    position: relative;
    z-index: 5;
}

.switch-dashboard-label-list2 #switchDashboardLabelList {
    width: 100%;
    min-width: 27px;
    margin-top: 8px;
}

.switch-dashboard-label-list2 #switchDashboardLabelList .ant-select-arrow {
    right: 7px;
    color: rgb(210, 210, 210);
}

.switch-dashboard-label-list2 #switchDashboardLabelList .ant-select-selection {
    background: none;
    border-color: #2f3337;
    color: rgb(210, 210, 210);
}

.switch-dashboard-list {
    color: #ffffff;
    position: relative;
    z-index: 2;

}

.switch-dashboard-items {
    max-height: calc(100dvh - 305px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 4px;
    margin-bottom: 30px;
}

.switch-dashboard-item {
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 300ms ease-out;
    padding: 1px 3px;
    margin-left: 5px;
}

.switch-dashboard-item:hover {
    cursor: pointer;
    background: #ffffff33;
    border-radius: 30px;
}

.switch-dashboard-item .switch-dashboard-item-icon {
    font-size: 23px;
    margin-right: 10px;
}

.switch-dashboard-item .switch-dashboard-item-circle {
    font-size: 23px;
    margin-right: 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 2px solid;
    border-radius: 50%;
    padding: 3px;
    position: relative;
    top: 2px;
    left: 3px;
}

.switch-dashboard-item .switch-dashboard-item-circle .inner-circle {
    background: #ffffff00;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 300ms ease-in-out;
}

.switch-dashboard-item.selected .switch-dashboard-item-circle .inner-circle {
    background: #ffffff;
}

.switch-dashboard-item .switch-dashboard-item-text {
    font-size: 14px;
    top: -1px;
    position: relative;
}

.switch-dashboard-list-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    margin: 0 0 5px 5px;
    display: block;
    padding: 0 4px;
}

.switch-dashboard .search-area input {
    padding-left: 38px;
    color: rgb(210, 210, 210);
    margin: 8px 0
}

.switch-dashboard-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #3a4044;
}

.switch-dashboard-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #3a4044;
    position: absolute;
}

.switch-dashboard-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff30;
    position: absolute;
}

.switch-dashboard-label-list-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    display: inline;
    padding-left: 34px;
}

.switch-dashboard-expand-button {
    position: absolute;
    color: #ffffff55;
    font-size: 15px;
    bottom: 8px;
    width: 100%;
    text-align: center;
    transition: all 300ms ease-out;
    cursor: pointer;
    z-index: 2;
}

.switch-dashboard.expand .switch-dashboard-expand-button {
    color: #ffffff;
}

.switch-dashboard .subtitle-icon {
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    top: 2px;
    position: absolute;
    left: 12px;
}

.switch-dashboard-tools {
    padding: 0 9px;
    display: none;
    position: relative;
    margin-top: 20px;

}

.switch-dashboard.expand .switch-dashboard-label-list,
.switch-dashboard.expand .switch-dashboard-label-list2,
.switch-dashboard.expand .switch-dashboard-tools {
    display: block;
}

.switch-dashboard .strait-title {
    height: 174px;
    width: 100%;
    position: relative;
}

.switch-dashboard .strait-title .strait-inner-text {
    color: #ffffff;
    display: block;
    font-size: 20px;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    position: absolute;
    top: 50%;
    left: 50%;
}

.switch-dashboard.expand .strait-title {
    display: none;
}

.expand-trigger-area {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}