.cache-mode-main-container {
    opacity: 0.75;
    top: 0;
    left: 0;
    z-index: 1001;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
    background: #274869;
    transition: .3s;
    display: flex;
}

.cache-mode-text {
    font-size: 12px;
    margin-bottom: 15px;
    padding: 0px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    text-shadow: 0px 0px 1px black;
    max-width: 60%;
    margin: auto;
    cursor: pointer;
}

.exit-cache-mode {
    font-size: 20px !important;
    color: rgb(65, 109, 142) !important;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #bbbbbb;
    box-shadow: 0px 0px 1px #757575;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unsaved-changes {
    position: absolute;
    left: 15px;
    bottom: 15px;
}

.unsaved-changes-icon {
    font-size: 30px;
    color: #ffa700;
    font-weight: bold;
}

.cache-check-icon {
    font-size: 24px;
    color: white;
    background: #359424;
    border-radius: 60px;
    padding: 4px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cache-icon {
    font-size: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #626465;
    margin-right: 10px;
}