.modal-header {
    margin: auto;
    width: 50%;
    padding: 10px;
    border: none
}

.modal-header-search-input {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #c4c2c2;
    color: #c4c2c2;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    box-shadow: none;
}

.modal-body {
    width: 100%;
    height: 450px;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: flex-start;
    text-align: center;
    border: 1px solid #c4c2c2
}

.ant-modal-footer {
    border: none;
}

.measure-general-body {
    text-align: center;
}

#addJoinButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.per-icon {
    margin: 10px;
    width: 50px;
    height: 50px
}

.per-icon:hover {
    color: #292929;
    cursor: pointer
}

.select-icon-text {
    font-size: 30px;
    font-weight: 400;
}

.ant-input:hover {
    border-color: #292929;
    border-right-width: 1px !important;
}

.ant-input:focus {
    border-color: #292929;
    border-right-width: 1px !important;
}