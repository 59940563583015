.dropdown-item .modellist-item:hover {
    border-radius: 8px;
    background-color: #c9d6e2;
}

.selection-menu .dropdown-item:hover {
    background-color: #e9f1f5;
    background-origin: unset;
    border-radius: 8px;
    cursor: pointer;
    border-bottom: 1px solid #236190;
}

.selection-menu .dropdown-item:focus {
    background-color: red;
    background-origin: unset;
    border-radius: 8px;
    cursor: pointer;
    border-bottom: 1px solid #236190;
}

.selection-menu .dropdown-item:active .dropdown-item {
    background-color: #84c458;
    color: #fff !important;
}


.datasource-connection-list .datasource-list {
    display: none;
    width: 100%;
    padding: 30px;
    top: 0;
    position: absolute;
    left: -20px;
    height: 150px;
}

.datasource-connection-list:focus .datasource-list {
    display: block;
}

.form-control .model-name {
    border: none;
    border-bottom: 1px solid #dadada;
    border-radius: 0;
    padding-left: 38px;
    background: none;
    width: 100%;
    min-width: 250px;
    z-index: 2;
    position: relative;
    transition: none;
}

.data-source-tab .ant-tabs-nav-wrap {
    text-align: left;
}