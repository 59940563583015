.box-item-component {
    position: relative;
}

.box-item {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 8px;
    white-space: normal;
    display: flex;
    height: 35px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-bottom: none;
    color: dimgrey;
    background: #f3f3f3;
    filter: brightness(1);
    transition: .4s;
}

.box-item.selected {
    background-color: #a3f0b0 !important;
    color: #426945;
}

.box-item.selected.newColumn {
    background-color: #b7edef !important;
    color: #426945;
}

.box-item.selected:hover {
    filter: brightness(0.98);
}

.box-item.onColumnMap {
    color: #585858;
}

.box-item.onColumnMap.replacedColumn {
    font-style: italic;
}

.box-text.onColumnMap.Active {
    max-width: 160px;
}

.box-item .box-text {
    position: relative;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
}
/* 
.box-item span {
    position: relative;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    padding: 0 7px;
    width: 100%;
} */

.box-item-content {
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    margin-bottom: 9px;
    background: #f7f7f7;
    box-shadow: 0px 1px 2px 0px;
    position: relative
}

.box-item-extra {
    position: absolute;
    top: 3px;
    right: 5px;
    color: #484848;
}

.hide-box-item {
    position: absolute;
    top: 9px;
    z-index: 1;
    color: #585858;
}