.double-button .btn:first-child {
    margin-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: -moz-fit-content;
    padding: 0 9px;
    height: 40px;
}

.double-button .btn:nth-child(2) {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: -moz-fit-content;
    padding: 0 9px;
    padding-left: 4px;
    height: 40px;
}

.excepted-plugins-area {
    position: relative;
    border: 1px solid #e4dfdf;
    border-radius: 5px;
    padding: 7px;
    background: white;
    height: 275px;
    margin-bottom: 40px;
}

.excepted-plugins {
    height: 225px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.excepted-plugins .excepted-item {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.excepted-plugins-area .disabled-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(239, 239, 239, 0.58);
    z-index: 2;
}

.button-area {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px 13px;
    background: #fff;
    border-top: 1px solid #d8d8d8;
    box-shadow: 0px -2px 2px -1px #ececec;
    border-radius: 5px;
    z-index: 3;
}

.timer-area {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
}

.timer-area .timer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    animation-name: fillTimer;
    animation-duration: 0s;
    background-color: #236090;
    opacity: 0.5;
    animation-iteration-count: infinite;
}

.timer-area .timer:hover,
.timer-area:hover .timer,
#refreshButton:hover .timer {
    background: #236090;
    ;
}

@keyframes fillTimer {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.timer-area .clock {
    position: absolute;
    bottom: 1px;
    right: 0px;
    font-size: 12px;
}

.animation-none {
    animation: none !important;
}