#modelSelectionArea .ant-select-selection-selected-value {
    margin-top: 5px;
}
#modelSelectionArea .anticon-profile {
    vertical-align: 1px;
}
#modelSelectionArea .ant-select-search--inline {
    margin-top: 5px;
}

#modelSelectionArea .ant-select-selection--single {
    height: 40px !important;
}
