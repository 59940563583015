/* Dashed border */
hr.dashed {
  border-top: 3px dashed #bbb;
}

/* Dotted border */
hr.dotted {
  border-top: 3px dotted #bbb;
}

/* Solid border */
hr.solid {
  margin-top: 0px;
  padding-top: 0px;
  border-top: 1px solid #d5dadc;
  margin: 0px !important;
}

/* Rounded border */
hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

.icon-text-text {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-text-icon {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
}

.ant-row-padding-full-sl {
  padding: 2px 10px;
}

.ant-row-padding-sides-sl {
  padding: 5px 10px 4px 10px !important;
}

.apply-button-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.apply-button-deep {
  font-size: 16px;
  margin-bottom: 5px !important;
  padding: 0px 40px !important;
}

.link-button {
  color: #17a6c9;
  border: 0px;
  text-align: center;
  padding-left: 0px;
  padding-top: 5px;
  box-shadow: none;
  padding-right: 0px !important;
}