.dashboard-comment-header-area {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  flex-direction: column;
  margin-top: -2px;
  min-height: 30px;
  flex-direction: column;
}

.comment-text {
  margin: 0;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  color: #202124;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-comment-buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;
}

.add-comment-button {
  margin-right: 7px;
  font-size: 25px;
  margin-top: 5px;
}

.dashboard-comment-body {
  height: calc(100% - 125px);
  overflow: scroll;
}

.dashboard-add-comment-body {
  min-height: 70px;
}

.dashboard-empty-comment-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: calc(100% - 10px);
}

.empty-comment-background-icon {
  font-size: 200px !important;
  opacity: 0.1;
}

.empty-comment-information-text {
  margin-top: 10px;
  color: #606264;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 2px;
}

.empty-comment-body-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-comment-information-bottom-text {
  display: inline-block;
  height: 84px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-top: 10px;
  color: #a0a6ab;
  max-width: 144px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: 191px;
  letter-spacing: 0.2px;
  text-align: center;
}

.empty-comment-information-bottom-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mention-inputs-main {
  height: 70px;
}

.dbComment-comment {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  background: white;
  transition: 0.3s;
}

.dbComment-user {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.dbComment-user-body {
  width: 70px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 70px;
  min-height: 55px;
}

.dbComment-comment {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.dbComment-reply-no-border {
  border-bottom: none !important;
  padding-bottom: 0px;
}

.dbComment-reply-no-border-last {
  border-bottom: none !important;
  padding-bottom: 10px;
}

.dbComment-comment-comment-body {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 95px);
}

.dbComment-userName {
  font-weight: 600;
  color: #575757;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.dbComment-header {
  width: 95%;
  height: 4px;
  box-sizing: border-box;
  background: #d8d8d8;
  margin-top: 7px;
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s;
}

.dbComment-footer {
  width: 95%;
  box-sizing: border-box;
  height: 2px;
  box-sizing: border-box;
  background: #d8d8d8;
  border-radius: 0px 0px 15px 15px;
  transition: 0.5s;
  margin-bottom: 4px;
}

.dashboard-add-comment-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  background: white;
  transition: 0.3s;
}

.dashboard-add-comment-area:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
}

.ant-mentions {
  width: 90%;
  border: none !important;
  box-shadow: none !important;
  background: none;
}

.ant-mentions:hover .ant-mentions:focus {
  border: none !important;
  box-shadow: none !important;
  background: none;
}

.ant-mentions-focused:hover .ant-mentions-focused:focus {
  border: none !important;
  box-shadow: none !important;
  background: none;
}

#addCommentBaseArea {
  background: none;
}

.send-button {
  font-size: 21px;
  transition: 0.3s;
  cursor: pointer;
  color: #adadad;
}

.send-button:hover {
  color: #225f8f;
}

.db-comment-wrapper {
  border-top: 10px solid;
  border-bottom: 3px solid;
  border-radius: 10px;
  border-color: #a7a6a6;
  width: 95%;
  overflow: hidden;
  margin-top: 5px;
  transition: 300ms all ease;
}

.dbComment-reply {
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;
}

.edit-comments {
  font-size: 12px;
  padding: 0;
  width: 90% !important;
  line-height: 15px;
}

.edit-comments #addCommentBaseArea {
  padding: 0 !important;
}

.dashboard-comment-edit-button {
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
}

.dbComment-editted-text {
  font-size: 8px;
  margin-left: 7px;
  color: #a2a2a2;
}

.dbComment-loading-screen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 1;
}

.dashboard-comment-tooltip {
  font-size: 11px;
}

.comment-text-area {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  align-self: baseline;
}

.comment-filter {
  width: 100%;
  display: grid;
}

.search-area-divider {
  margin-top: 7px;
  margin-bottom: 0px;
}

.add-comment-button-mobile {
  display: none;
}

@media (min-width: 585px) and (max-width: 992px) {
  .dashboard-comments-popup .popupContainer {
    left: calc(-475px) !important;
    width: 470px !important;
  }
}

@media only screen and (min-width: 510px) and (max-width: 584px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -80vw !important;
    width: 100vw !important;
  }

  .db-comment-sort {
    width: 37vw !important;
  }

  .dashboard-comment-buttons {
    width: 32vw;
  }
}

@media only screen and (min-width: 470px) and (max-width: 509px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -78vw !important;
    width: 100vw !important;
  }

  .db-comment-sort {
    width: 34vw !important;
  }

  .dashboard-comment-buttons {
    width: 30vw;
  }
}

@media only screen and (min-width: 430px) and (max-width: 469px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -75.5vw !important;
    width: 100vw !important;
  }

  .db-comment-sort {
    width: 30vw !important;
  }

  .dashboard-comment-buttons {
    width: 25vw;
  }
}

@media only screen and (min-width: 401px) and (max-width: 429px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -74vw !important;
    width: 100vw !important;
  }

  .db-comment-sort {
    width: 32vw !important;
  }

  .dashboard-comment-buttons {
    width: 30vw;
  }

  .comment-text {
    width: 30vw;
    font-size: 15px;
    margin-left: -10px;
  }

  .dashboard-comment-buttons {
    gap: 3px !important;
    margin-left: -1px;
  }

  .dashboard-add-comment-area .ant-mentions {
    font-size: 14px;
  }
}

@media only screen and (min-width: 381px) and (max-width: 400px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -73vw !important;
    width: 100vw !important;
  }

  .db-comment-sort {
    width: 32vw !important;
  }

  .dashboard-comment-buttons {
    width: 30vw;
  }

  .comment-text {
    width: 30vw;
    font-size: 15px;
    margin-left: -10px;
  }

  .dashboard-comment-buttons {
    gap: 3px !important;
    margin-left: -1px;
  }

  .dashboard-add-comment-area .ant-mentions {
    font-size: 14px;
  }
}

@media only screen and (min-width: 341px) and (max-width: 380px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -72vw !important;
    width: 99.5vw !important;
  }

  .db-comment-sort {
    width: 30vw !important;
  }

  .dashboard-comment-buttons {
    width: 30vw;
  }

  .comment-text {
    width: 30vw;
    font-size: 14px;
    margin-left: -10px;
  }

  .dashboard-comment-buttons {
    gap: 3px !important;
  }

  .dashboard-add-comment-area .ant-mentions {
    font-size: 12px;
  }
}

@media only screen and (min-width: 317px) and (max-width: 340px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -66vw !important;
    width: 99.5vw !important;
  }

  .db-comment-sort {
    width: 30vw !important;
  }

  .dashboard-comment-buttons {
    width: 25vw;
  }

  .comment-text {
    width: 30vw;
    font-size: 14px;
    margin-left: -10px;
  }

  .dashboard-comment-buttons {
    gap: 3px !important;
    margin-left: 5px;
  }

  .dashboard-add-comment-area .ant-mentions {
    font-size: 12px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 316px) {
  .dashboard-comments-popup .popupContainer {
    top: calc(43px) !important;
    left: -78vw !important;
    width: 99.5vw !important;
  }

  .db-comment-sort {
    width: 45vw !important;
    margin-right: 10px;
  }

  .dashboard-comment-buttons {
    width: 25vw;
  }

  .comment-text {
    width: 30vw;
    font-size: 11px;
    margin-left: -10px;
  }

  .dashboard-comment-buttons {
    gap: 4px !important;
  }

  .dashboard-add-comment-area .ant-mentions {
    font-size: 9px;
  }

  .comment-text {
    display: none;
  }

  .add-comment-button-mobile {
    display: block;
    top: calc(9px);
    position: relative;
  }
}

.new-message-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -7px;
  right: -7px;
  border: 1px solid #236090;
  border-radius: 100%;
  background: #236090;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1023px) {
  .new-message-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -7px;
    right: -7px;
    border: 1px solid #236090;
    border-radius: 100%;
    background: #236090;
    color: white;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-height: 910px) and (max-height: 967px) {
  .dashboard-comments-popup-container {
    height: 87vh;
  }

  .dashboard-comments-popup-container .innerPopup {
    height: 87vh !important;
  }
}

@media only screen and (min-height: 740px) and (max-height: 909px) {
  .dashboard-comments-popup-container {
    height: 83vh;
  }

  .dashboard-comments-popup-container .innerPopup {
    height: 83vh !important;
  }
}

@media only screen and (min-height: 658px) and (max-height: 739px) {
  .dashboard-comments-popup-container {
    height: 85vh;
  }

  .dashboard-comments-popup-container .innerPopup {
    height: 85vh !important;
  }
}

@media only screen and (min-height: 450px) and (max-height: 657px) {
  .dashboard-comments-popup-container {
    height: 84vh;
  }

  .dashboard-comments-popup-container .innerPopup {
    height: 84vh !important;
  }
}

@media only screen and (min-height: 279px) and (max-height: 450px) {
  .dashboard-comments-popup-container {
    height: 143vh !important;
  }

  .dashboard-comments-popup-container .innerPopup {
    height: 145vh !important;
  }
}