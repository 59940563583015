.addButton {
    background-color: rgb(254, 255, 255);
}

.ant-table-tbody > tr > td {
    padding: 0px 16px;
    overflow-wrap: break-word;
    font-weight: bold;
}

.empty-area-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #afafaf;
    text-align: center;
}

.empty-area-text>.icon {
    font-size: 48px;
    margin-bottom: 8px;
}

.empty-area-text>span {
    font-size: 14px;
}