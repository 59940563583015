.marker-cluster {
    background-clip: padding-box;
    background: #333333;
    border-radius: 20px;
    border: none;
    background: none
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font-family: Open Sans, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.leaflet-right {
    left: 0!important;
    right: auto!important;
}

.marker-cluster span {
    line-height: 30px;
}

.leaflet-div-icon {
    background: none;
    border: none;
}

.leaflet-marker-icon .dot {
    border-radius: 30px;
    background-color: #333333;
    border: 5px solid;
    width: 0px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}