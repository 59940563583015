.saveAsArea {
    position: absolute;
    left: calc(50% - 145px);
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 1px 3px -1px #9c9c9c;
    z-index: 2;
}

.saveAsArea .item {
    padding: 5px 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.saveAsArea .item:last-child {
    border-bottom: none;
}

.saveAsArea .item:hover {
    background: #226191;
    color: #fff;
}