.pagePopup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.containerIcon {
    position: absolute;
    display: flex;
    border-top: 1px solid;
    border-bottom: 1px solid;
    font-size: 17.5px;
    transform: scale(1, 3);
    color: #ababab;
    background: white;
    background: -webkit-linear-gradient(to right, #ffffff, #fbfbfb);
    background: linear-gradient(to right, #ffffff, #fbfbfb);
    border-color: #5c7385;
}

.save-button-area {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: white;
    padding: 10px 23px;
    left: 0;
    box-shadow: 0px -2px 2px -1px #cecece, 0px -2px 2px 0px #ffffff
}