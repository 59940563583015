.no-data-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 7px;
    z-index: 2;
}

.no-data-content span {
    width: 100%;
    padding: 15px;
    text-align: center;
    display: block;
    margin: auto;
    color: #126faf;
    z-index: 1;
}

.no-data-text {
    font-size: 11px;
    padding-top: 0px !important;
    line-height: 10px;
    top: -8px;
    position: relative;
}