.dashboard-mail-popup-container {
  height: fit-content;
}

.dashboard-mail-popup-container .innerPopup {
  height: fit-content !important;
}

@media screen and (max-width: 1023px) {
  .dashboard-mail-popup-container {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    right: 0 !important;
    left: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    z-index: 10000;
  }

  .dashboard-mail-popup-container .innerPopup {
    padding: 8px !important;
    height: 100% !important;
    max-height: 100% !important;
  }

  .dashboard-mail-header-area #pinTop {
    display: none !important;
  }

  .dashboard-mail-body {
    height: calc(100% - 80px) !important;
    max-height: calc(100% - 80px) !important;
  }
}


.dashboard-mail-header-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: -2px;
  height: 40px;
  border-bottom: 1px solid #e8e8e8
}

.mail-text {
  margin: 0;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  color: #202124;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-mail-buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;
}

.send-mail-button {
  margin-right: 7px;
}

.dashboard-mail-body {
  width: 100%;
  padding: 8px 6px;
  max-height: calc(70vh - 80px);
  overflow: auto;
}

.send-mail-button-mobile {
  display: none;
}

.dashboard-mail-receipents {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
}

.dashboard-mail-receipents-content {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 28px;
  max-height: 80px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.section-font {
  font-weight: 600;
  font-size: 14px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
}

.empty-mail-area {
  width: 100%;
  background-color: rgb(251, 251, 251);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.three-dot {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100%;
}

.dashboard-mail-footer {
  height: 40px;
  padding: 10px 4px 0 4px;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #e8e8e8;
}

.dashboard-mail-footer button {
  margin: 0;
  width: fit-content;
  min-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.export-type-item {
  display: flex;
  align-items: center;
  margin-right: 8px !important;
  margin-left: 0px !important;
}

.discard-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.discard-confirm-popup {
  width: fit-content !important;
  max-height: calc(100% - 32px) !important;
  max-width: calc(100% - 32px) !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.discard-confirm-button {
  background-color: rgb(249, 47, 47);
  border-bottom: 2px solid rgb(145, 28, 28);
}

.discard-confirm-button:hover,
.discard-confirm-button:active,
.discard-confirm-button:focus,
.discard-confirm-button:visited {
  background: rgb(249, 47, 47);
  border-bottom: 2px solid rgb(145, 28, 28);
}
