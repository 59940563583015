.dashboard-slider-top,
.dashboard-slider-bottom {
    left: 0;
    height: 32px;
    width: 100%;
    z-index: 10000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-slider-top {
    top: 0;
}

.dashboard-slider-bottom {
    bottom: 0;
}

.dashboard-slider-top>div,
.dashboard-slider-bottom>div {
    display: flex;
    position: absolute;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;
    color: #FFFFFFFF;
    background-color: #000000AA;
    box-shadow: 0 0 4px rgba(103, 103, 103, 0.62);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.dashboard-slider-top>div {
    height: 40px;
}

.dashboard-slider-bottom>div {
    min-height: 50px;
}

.dashboard-slider-top .dashboard-slider-title-container {
    min-width: 350px;
    max-width: calc(75% - 112px);
    align-self: center;
    overflow: hidden;
    flex-direction: column;
    cursor: default;
}

.dashboard-slider-title-container .dashboard-slider-title {
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
}

.dashboard-slider-top .dashboard-slider-exit {
    width: 40px;
    cursor: pointer;
    left: 8px;
    font-size: 16px;
}

.dashboard-slider-top .dashboard-slider-exit .anticon-close {
    font-size: 16px !important;
    top: 0;
}

.dashboard-slider-bottom .dashboard-slider-action-container {
    min-width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #FFFFFF90;
}

.dashboard-slider-action-button-container .dashboard-slider-action-button {
    font-size: 16px;
    margin: 0 4px;
    transition: all 0.3s ease-in-out;
    color: #FFFFFF90;
}

.dashboard-slider-action-button-container .dashboard-slider-action-button#stop-button {
    background-color: #FFFFFF90;
    height: 16px;
    width: 16px;
    border-radius: 2px;
}

.dashboard-slider-action-button-container .dashboard-slider-action-button#play-button,
.dashboard-slider-action-button-container .dashboard-slider-action-button#pause-button,
.dashboard-slider-action-button-container .dashboard-slider-action-button#forward-button,
.dashboard-slider-action-button-container .dashboard-slider-action-button#backward-button {
    font-size: 30px;
    color: #FFFFFFFF;
}

.dashboard-slider-bottom .dashboard-slider-progress-bar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 1;
    cursor: default;
}

.dashboard-slider-progress-bar-container .text-time {
    color: #FFFFFFAA;
    user-select: none;
    -webkit-user-select: none;
}

.dashboard-slider-progress-bar-container .dashboard-slider-progress-bar {
    height: 6px;
    width: 100%;
    border-radius: 6px;
    margin: 0 4px;
    overflow: hidden;
    background-color: #FFFFFF1A;
}

.dashboard-slider-progress-bar-container .dashboard-slider-progress {
    height: 100%;
    width: 0;
    background-color: #FFFFFF90;
    border-radius: 4;
}