.self-service-container {
    height: 250px;
}

.self-service-row {

}

.self-service-input {
    margin-bottom: 15px;
}

.self-service-input:hover {
    border-color: #909090!important;
}

.self-service-input:focus {
    border-color: #909090!important;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}