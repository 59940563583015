@media (min-width: 768px) and (max-width: 992px) {
    .plugin-list .popupContainer {
        top: 40px !important;
        left: -40px !important;
        width: 500px !important;
    }

    .datasource-menu .popupContainer {
        left: 40px !important;
        right: 0 !important;
        width: 700px !important;
    }

    .join-modal {
        width: 95% !important;
    }
}

@media only screen and (max-width: 1023px) {
    .navbar .top-menu-search .search-input-area {
        position: unset !important;
    }

    .top-menu-search .top-menu-search-overlay {
        display: none;
        z-index: 0;
    }

    .navbar .top-menu-search .resultHolder {
        position: fixed;
        top: 57px;
        left: 0;
        height: calc(100dvh - 57px);
        max-height: none;
        width: 100dvw;
        border-radius: 0;
        z-index: 1000 !important;
    }

    .navbar .top-menu-search .resultHolder .searchedDashboard #resultTitle {
        font-size: 13px !important;
    }

    .navbar .top-menu-search .resultHolder .searchedDashboard #resultDate {
        font-size: 11px !important;
    }

    .navbar .top-menu-search .resultHolder .searchedDashboard #resultLabels,
    .navbar .top-menu-search .resultHolder .searchedDashboard #resultDescription {
        display: none !important;
    }

    .what-if-parameters {
        height: 30px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: fit-content !important;
        max-width: 50px;
    }

    .double-button .btn {
        height: 30px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .double-button * {
        font-size: 16px !important;
    }

    .mobile-menu-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px !important;
        width: 30px !important;
        padding: 4px !important;
        font-size: 14px !important;
    }

    .circle-button {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        padding: 4px !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle-button * {
        font-size: 16px !important;
    }

    .show-all {
        margin-left: 8px !important;
    }

    .label-bar * {
        font-size: 14px !important;
    }

    .label-bar h4 {
        text-align: left;
    }

    .empty-dashboard {
        line-height: initial;
    }

    .empty-dashboard .text {
        font-size: 18px;
    }

    .copyright {
        font-size: 12px;
    }

    .mobile-menu {
        display: block;
    }

    .hide-on-mobile {
        display: none !important;
    }

    .show-on-mobile {
        display: block;
    }

    .navbar .top-menu-search .search-area {
        position: absolute;
        z-index: 1;
        width: calc(100% - 60px) !important;
        left: 50px;
        top: 10px;
    }

    .navbar .top-menu-search .back-icon {
        position: absolute;
        z-index: 2;
        left: 13px;
        top: 14px;
        display: flex;
        padding: 5px;
        color: #8a8a8a;
        border-radius: 50%;
        box-shadow: 0 0 2px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        background: #fff;
    }

    .navbar .top-menu-search {
        display: none;
    }

    .navbar .top-menu-search .search-input-area {
        background: #efefef;
        width: 100%;
        border-radius: 50px;
        overflow: hidden;
    }

    .navbar .top-menu-search .search-area input:focus {
        border: none;
    }

    .navbar-expand-lg {
        justify-content: space-between !important;
        align-items: center !important
    }

    .dashboard-header {
        height: 57px !important;
    }

    #vispeahenImage {
        width: 40%;
    }

    #vispeahenImage img {
        max-width: 100%;
    }

    .dashboard-detail-button .popupContainer {
        width: 100% !important;
        top: 32px !important;
        right: 0 !important;
    }

    [page=landing-page] #labelButton {
        display: none;
    }

    [page=landing-page] .dashboard-setting {
        height: 0;
        width: 0;
        border: 0;
        margin: 0;
        padding: 0;
    }

    [page=landing-page] .dashboard {
        height: 100%;
    }

    .dashboard-label-sort-icon {
        margin: 0 5px;
    }

    .dashboard-label-sort-icon .popupContainer {
        width: 200px !important;
        left: -100px !important;
        top: 20px !important;
    }

    [page=dashboard] .top-menu-search,
    [page=dashboard] .search-button {
        display: none;
    }

    [page=dashboard] .dashboard-setting .left-tools {
        width: 80%;
    }

    [page=dashboard] .dashboard-setting .middle-tools {
        display: none;
    }

    [page=dashboard] .dashboard-setting .right-tools {
        width: 20%;
    }

    [page=dashboard] .dashboard-setting .left-tools .dashboard-button,
    [page=dashboard] .dashboard-setting .left-tools .double-button,
    [page=dashboard] .dashboard-setting .right-tools .dashboard-button,
    [page=dashboard] .dashboard-setting .right-tools .double-button,
    [page=dashboard] .dashboard-setting .middle-tools .dashboard-button,
    [page=dashboard] .dashboard-setting .middle-tools .double-button {
        margin: 0 2.5px !important;
    }

    [page=dashboard] .dashboard-setting .double-button>.dashboard-button {
        margin: 0 !important;
    }

    .scroll-to-top {
        right: 10px !important
    }

    .auto-refresh-area .popupContainer {
        left: -10px !important;
        top: 48px !important;
        width: 270px !important;
    }

    .auto-refresh-area .button-area button {
        width: 45% !important;
    }

    .mobile-plugin-grid {
        width: 100% !important;
        transform: none !important;
        margin: 10px 0 !important;
    }

    [plugin=true] {
        min-height: 50px;
    }

    .navigation-filters-used-button {
        width: 40px !important;
    }

    .navigation-filters-used-button .popupContainer {
        width: 280px !important;
        right: 0 !important;
        left: auto !important;
        top: 45px !important;
    }

    .navigation-filters-used-button .popupContainer .ant-table {
        font-size: 12px !important;
    }

    .switch-visible-button {
        bottom: 100px !important;
    }

    .switch-area.visible .switch-visible-button {
        display: none;
    }

    .right-tools {
        width: 37% !important;

    }
}

@media only screen and (max-height: 550px) {
    .ant-drawer-body {
        padding: 12px 24px;
    }

    .ant-drawer-left .ant-drawer-content-wrapper,
    .ant-drawer-right .ant-drawer-content-wrapper,
    .ant-drawer-content-wrapper {
        height: 96% !important;
        top: 2% !important;
        min-height: auto !important;

    }

    .login-logo {
        max-height: 100px !important;
        max-width: 100% !important;
        margin: 12px auto !important;
        width: initial !important;
        height: initial !important;
    }

    #footer {
        display: none !important;
    }

    .switch-visible-button {
        bottom: calc(50% - 18px) !important;
    }

    .switch-area.visible .switch-visible-button {
        display: none;
    }
}