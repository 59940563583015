  .modellingContent {
    padding: 8px;
  }

  #createModelButton {
    float: right;
  }

  #saveModelButton {
    float: right
  }

  .contentFooter {
    position: relative;
    bottom: 0;
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
  }

  .contentFooter button {
    margin-left: 8px;
  }

  .centeredItemRow {
    text-align: center;
    vertical-align: 50%;
    margin: auto;
  }

  .center {
    margin:50px;
    width: 40%;
    border: 3px solid silver;
    background-color: #FFFFFF;
    padding: 8px;
    /* flex: 1;
    overflow-y: scroll; */
  }