.report-skeleton-text {
    background: #eaeaea;
    width: 100%;
    height: 20px;
    border-radius: 6px;
}

.report-skeleton-mini-text {
    background: #eaeaea;
    width: 20%;
    height: 12px;
    border-radius: 6px;
    display: inline-block;
    top: 0px;
    position: relative;
    margin-right: 5px;
}

.report-skeleton-image {
    background: #eaeaea;
}

.with-animation {
    animation: skeletonLoadingAnimate 1s infinite;
}

@keyframes skeletonLoadingAnimate {
    25% {
        background: #eaeaea;
    }

    75% {
        background: rgb(214, 214, 214);
    }

    100% {
        background: #eaeaea;
    }
}