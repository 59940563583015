.generate-modal  .ant-modal-content {
    width: fit-content;
    height: fit-content;
}

.generate-modal .ant-modal-header {
    padding: 16px;
}

.generate-modal .ant-modal-body {
    padding: 0;
    min-height: 264px;
    min-width: 264px;
    height: 70dvh;
    width: 90dvw;
    max-width: 1000px;
    max-height: 1000px;
}

.generate-modal .ant-modal-body .body-content {
    display: flex;
    padding: 8px;
    width: 100%;
    height: 100%;
}

.generate-modal .ant-modal-footer {
    padding: 16px;
}

.generate-modal .big-icon * {
    font-size: 48px !important;
}

.button-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.header-shadow  {
    border-bottom: 1px solid rgb(218, 218, 218);
    box-shadow: #dddddd 0 6px 6px -6px;
    padding-bottom: 7px !important;
    z-index: 1;
}

.generate-modal .ant-select > div:not([class]) {
    top: unset !important;
    left: unset !important;
}
    
.generate-modal .ant-select > div:not([class]) .ant-select-dropdown {
    top: unset !important;
    left: unset !important;
    position: relative !important;
    margin-bottom: 8px !important;
}

.generate-modal .parameter-warning {
    margin: 4px 0;
    padding: 8px !important;
    border-radius: 4px;
    background-color: #FF980028;
}

.generate-modal .parameter-error {
    margin: 4px 0;
    padding: 8px !important;
    border-radius: 4px;
    background-color: #ff777738;
}

.generate-modal .parameter-error .ant-alert-message {
    display: block;
    max-height: 70px;
    overflow-y: auto;
    font-size: 11px;
    color: #FF4747;
    padding-right: 8px;
}

.generate-modal .parameter-warning .ant-alert-message {
    display: block;
    max-height: 70px;
    overflow-y: auto;
    font-size: 11px;
    color: #FF9800;
    padding-right: 8px;
}

.generate-modal .output-settings {
    height: 100%;
}

.generate-modal .output-settings>.ant-tabs-bar {
    margin: 0
}

.generate-modal .output-settings>.ant-tabs-content {
    height: 100%;
    width: 100%;
}

.generate-modal .output-settings>.ant-tabs-content>.ant-tabs-tabpane{
    height: calc(100% - 36px);
    width: 100%;
    overflow: hidden auto !important;
    padding: 8px 16px;
}

.generate-modal .output-settings>.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive, .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
    display: none !important;
}

@media screen and (max-width: 767px) {
    .generate-modal .ant-modal-title {
        font-size: 14px;
    }

    .generate-modal .generate-modal .ant-modal-body * {
        font-size: 11px;
    }

    .generate-modal .generate-modal .ant-modal-footer * {
        font-size: 11px;
    }

    .generate-modal .generate-modal .ant-modal-content {
        width: 100%;
    }

    .generate-modal .ant-select-dropdown-menu-item {
        font-size: 11px;
    }
}