/* Import Google Font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.wrapper-context-menu {
  position: absolute;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #00000030;
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 3;
}

.context-menu * {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

#conversion-menu-confirmation .ant-popover-inner-content {
  font-size: 13px;
  max-width: 300px;
}

.wrapper-context-menu .menu-context-menu {
  max-height: 30dvh;
  overflow-y: auto;
  padding: 8px 10px;
  margin-bottom: 0px;
}

.item-context-menu {
  list-style: none;
  font-size: 13px;
  height: 30px;
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 2px;
  padding: 0 5px 0 3px;
}

.item-context-menu:hover {
  background: rgb(247, 246, 246);
}

.item-context-menu span {
  margin: 0;
  font-size: 13px;
}

.item-context-menu .label {
  margin-left: 8px;
}

.setting-context-menu {
  display: flex;
  margin-top: -5px;
  padding: 5px 12px;
  border-top: 1px solid #ccc;
}

.share-context-menu {
  position: relative;
  justify-content: space-between;
}

.share-context-menu .share-menu-context-menu {
  position: absolute;
  width: 200px;
  right: -212px;
  top: -39px;
  padding: 7px 7px 7px 0px;
  z-index: 3;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 0.2s ease;
}

.delete-item-context-menu {
  color: rgb(255, 118, 118);
}

.share-menu-context-menu {
  z-index: 3;
  position: absolute;
  left: 170px;
  width: 230px;
  padding: 10px 8px;
  margin-bottom: 0px;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid #00000030;
}

.mobile-context-menu {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-height: 60dvh;
  background-color: rgba(255, 255, 255, 0.9);
  border-top-left-radius: 8;
  border-top-right-radius: 8;
  box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.8);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  bottom: 0;
  left: 0;
  touch-action: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  padding: 16px 0px;
}

.mobile-context-menu .context-menu-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DADADA;
  padding: 0px 16px 8px;
}

@media screen and (max-width: 500px) {
  .item-context-menu {
    padding: 24px 5px;
    margin: 8px 0;
  }

  .item-context-menu span {
    font-size: 16px;
  }
}