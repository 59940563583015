.popupContainer {
    background-color: #fff;
    border: 1px solid #00000033;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 2px 6px 2px rgba(60, 64, 67, .15);
    position: absolute;
    z-index: 1000!important;
}

.popupContainer:hover {
    color: initial
}

.plugin-item {
    border-radius: 8px;
    border-bottom: 2px solid #fff0;
    list-style-type: none;
    padding: 4px 7px 4px 7px;
    vertical-align: top;
}

.plugin-item:hover {
    background-color: #e9f1f5;
    background-origin: unset;
    border-radius: 8px;
    cursor: pointer;
    border-bottom: 2px solid #236190;
}

.plugin-item-text {
    position: initial;
    display: block;
    color: #585858;
    white-space: normal;
}

.innerPopup {
    overflow: auto;
    padding: 15px 15px;
}

@media screen and (max-width: 768px) {
    .popupContainer {
        width: 100% !important;
    }
}

.innerPopup::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
}

.innerPopup::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
    position: absolute;

}

.innerPopup::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d3d3;
    position: absolute;
}