.logo {
  height: 64px;
  background: rgba(255, 255, 255, 0.2); 
  vertical-align: middle;
  display: inline-block;
  /* margin: 12px; */
}

.logo-image {
  width: 200px;
  padding: 10px;
  vertical-align: middle;
  margin-top: 6px;
}

.top-bar {
  background: #ffffff;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  /* padding: 0px; */
  vertical-align: center;
  position: relative;
  margin-bottom: 4px;
}

.topBarLeftItemContainer {
  float: right;
  top: 15px;
  margin-right: 15px;
}

.topBarLeftItem {
  background-color: #1890ff;
  margin: 2px;
}

/* .layout .content {
  margin: 24px 6px;
  background: rgb(255, 255, 255);
  min-height: 280px;
} */

.addButton {
  float: right
}