.dashboard-item-main {
    flex-direction: column;
    cursor: pointer;
}

.dashboard-item {
    margin-bottom: 5px;
    transition: .3s;
    padding: 5px;
    border-radius: 8px;
    width: 225px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.dashboard-item-main-dashboard {
    width: 270px!important;
    margin-bottom: 0px;
}

.dashboard-item:hover {
    background-color: #4e7ea2ac;
    color: white;
    transition: .3s;
}

.active-custom-dashboard {
    background-color: #4e7da2;
    color: white;
    transition: .3s;
}

.custom-dashboard-popover .ant-popover-title {
    text-align: center;
}

.custom-dashboard-popover .ant-popover-inner-content {
    padding: 5px 12px 5px 12px;
}

.custom-dashboard-title .ant-divider-inner-text {
    font-size: 14px;
    font-weight: bold;
    color: #5c5858;
    padding: 0px 12px;
}