.filters-list-area {
    min-width: 150px;
}

.filters-list-area .list-holder {
    position: relative;
    top: -3px;
    padding: 8px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    background: #f7f7f7;
    margin-bottom: 16px;
    overflow-x: hidden;
    overflow-y: auto;
}

.filters-list-area .list-holder .list-row {
    padding: 3px 1px;
}

.filters-list-area .list-holder .list-row:nth-child(even) {
    background: #f1f1f1;
}

.filters-list-title {
    border-bottom: 1px solid;
    border-color: #e4e4e4;
    position: relative;
    left: -16px;
    width: calc(100% + 32px);
    padding: 7px 0;
    text-align: center;
    top: -12px;
    color: #225f8f;
    margin: 0;
    font-size: 14px;
}

.filters-list-header {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 4px;
    text-align: center;
    padding: 0 4px;
}

.filters-list-text {
    font-size: 12px;
    color: #808080;
    text-align: center;
    padding: 0 4px;
}

.filters-list-text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.show-plugin-filters-button {
    z-index: 2;
    position: absolute;
    bottom: 2%;
    right: 2%;
    font-size: 15px;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    opacity: .4;
    transition: all 300ms ease;
    padding-top: 4px;
}

.show-plugin-filters-button:hover {
    opacity: 1;
    background: #226090;
    border-color: #226090;
    color: #fff;
}