.dashboard-slider-item {
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
}

.dashboard-slider-item:hover {
    background-color: #f8f9fa;
}

.dashboard-slider-item .colored-shape-custom {
    width: 15px;
    height: 15px;
    background: red;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #d8d8d8;
    position: relative;
    font-size: 12px;
    top: 3px;
}

.dashboard-slider-item .title-style {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    margin: 0px;
    color: rgb(71, 71, 71);
}

.dashboard-slider-item .title-style > i {
    margin-right: 8px;
}

.dashboard-sliders {
    overflow-y: scroll;
    overscroll-behavior-x: hidden;
    overscroll-behavior-y: auto;
}