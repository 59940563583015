#preferences-page-container {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#preferences-icon {
  font-size: 3rem;
  color: white;
  margin-bottom: 6px;
}

#preferences-content {
  width: 70%;
  display: flex;
}

#preferences-profile {
  flex: 1;
  background-color: #0a4c76;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: auto;
}

#preferences-settings {
  min-height: max-content;
  flex: 4;
  background-color: #e7f5fd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 36px;
  padding: 12px 0;
}
#preferences-setting-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
.preferences-setting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 90%;
}

.preferences-setting-input {
  height: 34px;
  resize: none;
  width: 70%;
}

#preferences-saved-link {
  font-size: 0.9rem;
  cursor: pointer;
  min-width: 60%;
  line-break: anywhere;
}
#preferences-saved-link:hover {
  text-decoration: underline;
}

#preferences-save-button {
  margin-bottom: 12px;
}

@media screen and (max-height: 600px) {
  #preferences-settings {
    justify-content: center;
    gap: 8px;
    padding: 0;
  }
}

@media screen and (max-height: 350px) {
  #preferences-icon {
    font-size: 2rem;
  }
  #preferences-settings > * {
    scale: 0.9;
  }
}

@media screen and (max-width: 1024px) {
  #preferences-content {
    flex-direction: column;
  }
  #preferences-profile {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
  #preferences-settings {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0;
  }
  .preferences-setting {
    flex-direction: row;
  }
  .preferences-setting-input {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .preferences-setting {
    flex-direction: column;
  }
  .preferences-setting-input {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  #preferences-settings > * {
    scale: 0.8;
  }
}
