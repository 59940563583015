@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=latin-ext');

a {
    text-decoration: none;
    color: #474747;
}

a:hover {
    color: #226190;
    text-decoration: none;
}

body {}

#superDeveloper111 {
    display: none;
}

.header-section {
    width: 100%;
    height: 57px;
}

.dashboard-header {
    border-bottom: 1px solid #dadada;
    width: 100%;
}

.btn-rounded {
    border-radius: 60px;
    border: 1px solid;
    /* font-size: 0.9vw; */
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    text-align: center;
    padding: 4px;
    padding-top: 6px;
}

.btn-rounded-sm {
    width: 25px;
    height: 25px;
    min-width: 25px;
    font-size: 12px;
    padding-top: 3px;
}

/** Search Input Stuff **/
.search-area {
    display: block;
    width: 100%;
    padding: 0 25px;
    position: relative;
}

.search-area input {
    border: none;
    border-bottom: 1px solid #dadada;
    border-radius: 0;
    padding-left: 38px;
    background: none;
    width: 100%;
    min-width: 250px;
    z-index: 2;
    position: relative;
    transition: none;
}

.search-area input.selected-category {
    padding-left: 88px;
}

.top-menu-search>.search-area input.selected-category {
    padding-left: 115px;
}

.top-menu-search .top-menu-search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 1;
}

.search-area span {
    position: absolute;
    left: 63px;
    z-index: 1;
    display: inline-block;
    top: 7px;
    opacity: 0;
    color: #777;
    transition: all 300ms ease;
}

.search-area .btn-search {
    position: absolute;
    top: 6px;
    left: 30px;
    color: #999;
    border: none;
}

.search-area input:focus {
    color: #777;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 0px 2px 0 rgba(60, 64, 67, .30), 0 1px 6px 2px rgba(60, 64, 67, .15);
    background-color: #ffffff00;
    transition: all 300ms ease;
}

.search-area input::placeholder {
    transition: all 300ms ease;
    transition-delay: 100ms;
}

.search-area input:focus::-webkit-input-placeholder {
    /* Edge */
    opacity: 0;
    transition: all 300ms ease;
}

.search-area input:focus:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0;
    transition: all 300ms ease;
}

.search-area input:focus::placeholder {
    opacity: 0;
}

.search-item-list {
    position: absolute;
    left: 0;
    top: 40px;
    background: #fdfdfd;
    display: block;
    z-index: 1;
    max-height: 0;
    height: auto;
    padding: 0;
    box-shadow: none;
    overflow: hidden;
    border-radius: 3px;
    transition: all 300ms ease;
    font-size: 14px;
}

.search-area input:focus+.search-item-list,
.search-area input:active+.search-item-list {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    max-height: 1000px;
}

.search-item-list.selected {
    max-height: 0 !important;
    box-shadow: none !important;
}

.search-item-list p {
    margin-bottom: 0;
    border-bottom: 1px solid #d8d8d8;
    text-align: left;
    cursor: pointer;
    padding: 8px 30px 8px 15px;
    color: #737373;
}

.search-item-list i {
    margin-right: 5px;
    font-size: 12px;
}

.search-item-list p:hover {
    background: #236090;
    color: #fff;
}

.search-item-list p:last-child {
    border-bottom: none;
}

.search-area .selected-category-on-input {
    position: absolute;
    top: 7px;
    left: 30px;
    padding: 2px 5px;
    background-color: #f1f1f1;
    font-size: 13px;
    width: 55px;
    text-align: center;
    color: #828282;
    z-index: 2;
}

.top-menu-search>.search-area .selected-category-on-input {
    width: 80px;
}

.search-input-area {
    width: 70%;
}

.search-area .selected-category-on-input p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.join-search-area.search-area input {
    min-width: inherit !important;
    padding: 5px !important;
}

.join-search-area.search-area {
    padding: 0px !important;
}

/** Dashboard Styling **/
section.dashboard-section {
    display: flex;
    position: relative;
}

.dashboard-setting {
    padding: .5rem 1rem;
    border-bottom: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    height: 57px;
}

.btn-new {
    padding: 5px 20px;
    border-radius: 30px;
}

.btn-new:hover {
    padding: 5px 20px;
    border-radius: 30px;
    color: white
}

.dashboard {
    padding: .5rem 2rem;
    height: calc(100% - 57px);
    overflow: auto;
}

#labels {
    position: relative;
}

.label-list {
    position: absolute;
    right: 0;
    width: 300px;
    padding: 4px 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    top: 0;
    transition: all 300ms ease;
}

.label-list .list-group {
    border: 1px solid #dadada;
    border-radius: 5px;
    background: #fff;
}

.label-list .list-group .list-group-item {
    background: none;
    color: #777;
}

.label-list .list-group .list-group-item .custom-control {
    padding: 0;
}

#labels:hover .label-list {
    opacity: 1;
    top: 35px;
    visibility: visible;
}

.report {
    border: 1px solid #e6e4e4;
    border-radius: 5px;
    background: #f3f3f3;
    margin-bottom: 30px;
    margin-top: 5px;
}

.report.active {
    box-shadow: 0px 0px 4px 2px #226090;
}

.report .report-thumb {
    padding: 16px;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
}

.report .report-image {
    height: 150px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 6px 2px #bdbdbd;
}

.report .report-thumb .details {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #08080880;
    color: #fff;
    padding: 5px;
    font-size: 13px;
    transition: all 300ms ease;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.report .report-thumb:hover .details {
    cursor: pointer;
    top: 0;
}

.report .report-thumb .details .details-inner {
    position: absolute;
    bottom: 0;
}

.report .report-thumb .details .details-inner .field {
    background: #2e7bff;
    padding: 1px 3px;
    border-radius: 3px;
}

.report .report-title {
    padding: 10px;
    background: #fff;
    border-top: 1px solid #e6e4e4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: left;
    height: 60px;
    position: relative
}

.report .report-title .report-detail {
    position: absolute;
    left: 12px;
    bottom: 7px;
    font-size: 13px;
    color: #9c9c9c;
}

.section {
    margin-bottom: 20px;
    margin-top: 15px;
}

.section:last-child {
    border-bottom: none;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none;
}

.disabled-btn a {
    cursor: not-allowed !important;
}

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
}

.footer-logo {
    margin: auto;
    background-position: left top;
    width: 49px;
    height: 45px;
    transform: scale(.8);
    /* filter: grayscale(1); */
    opacity: .3;
}

.username-tag {
    background: #84c458;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
}

.owl-prev {
    width: 22px;
    height: 100px;
    position: absolute;
    top: 35%;
    margin-left: -25px !important;
    display: block !important;
    border: 0px solid black;
    background: #ffffff00 !important;
}

.owl-next {
    width: 22px;
    height: 100px;
    position: absolute;
    top: 35%;
    right: -25px !important;
    display: block !important;
    border: 0px solid black;
    background: #ffffff00 !important;
    padding: 10px 20px;
}

.owl-prev i,
.owl-next i {
    color: #dddbdb;
    font-size: 20px;
    transform: scale(1, 2.5);
}

.resultHolder {
    display: block;
    position: absolute;
    top: 37px;
    background: #fff;
    height: auto;
    width: 100%;
    z-index: 2;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: hidden visible;
    transition: all 300ms ease;
    transition-delay: 200ms;
    max-height: 50dvh;
    box-shadow: 0 2px 2px 0 rgba(60, 64, 67, .30), 0 3px 6px 2px rgba(60, 64, 67, .15);
}

.searchedDashboard {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 20px;
    border-bottom: 1px solid #dadada;
    position: relative;
    border-left: 5px solid #fff;
    cursor: pointer;
    width: 100%;
}

.searchedDashboard:hover {
    border-left: 5px solid #246191;
    background: #f9f9f9;
}

.searchedDashboard:last-child {
    border-bottom: none;
}

.searchedDashboard img {
    display: inline-block;
    width: 20%;
    min-width: 20%;
    margin-right: 10px;
    border: 1px solid #dcdbdb;
    border-radius: 8px;
    padding: 10px;
    background: #ececec;
    pointer-events: none;
    aspect-ratio: 1;
    object-position: top;
    object-fit: cover;
}

.searchedDashboard p {
    display: inline-block;
    margin: 0;
}

.searchedDashboard span {
    opacity: 1;
    position: relative;
    left: 0;
    top: 0;
}

.searchedDashboard .detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.searchedDashboard .labels {
    margin-top: 8px;
}

.searchedDashboard .action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.searchedDashboard .creationDate {
    font-size: 11px;
    color: #a9a9a9;
    margin-bottom: 8px;
}

.searchedDashboard .generate-report {
    font-size: 24px;
    border: 1px solid;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    color: #246191;
    background-color: #fff;
}

.searchedDashboard .generate-report:hover {
    background-color: #246191;
    color: #fff
}

.dropdown-menu {
    min-width: 0 !important;
    padding: 0 !important;
}

.dropdown-item {
    padding: 3px 6px;
}

.text-shorten {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.noBefore> :before {
    content: none !important;
}

.custom-empty-option {
    min-height: 30px;
}

.dropdown-item:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    color: #fff0;
}

.dropdown-item.active:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    color: #84c457;
}

.colored-shape {
    width: 13px;
    height: 13px;
    background: red;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px #d8d8d8;
    top: -2px;
    position: relative;

}

.preview-area,
.custom-thumbnail-area {
    z-index: 3;
    position: absolute;
    display: none;
}

.preview-area.active,
.custom-thumbnail-area.active {
    display: block;
}


.preview-thumb,
.preview-temp-thumb {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    border: 1px solid;
}

.preview-thumb {
    width: 600px;
    height: 300px;
    margin: 10px 0;
    border-color: #00000033;
    box-shadow: 0px 0px 2px 1px #00000033;
}

.preview-temp-thumb {
    width: 300px;
    height: 150px;
    background-color: #ffffff33;
    border-color: #ffffff33;
    box-shadow: 0px 0px 2px 1px #ffffff33;
}

.preview-close {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    padding: 4px 10px;
    display: inline-block;
    background: #e27878;
    color: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 0px #d4cdcd;
}

.preview-close:hover {
    background: #e85252;
}

.preview-thumb .preview-edit-thumb {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: rgba(34, 96, 144, 0.5);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    transition: visibility 500ms ease, opacity 500ms ease;
}

.preview-thumb .edit-thumb {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 20px;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    color: white;
    background-color: rgba(34, 96, 144, 0.5);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.preview-thumb:hover .preview-edit-thumb {
    opacity: 1;
    visibility: visible;
}

.constant-text {
    color: #5f6368;
}

.styledScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
}

.styledScroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    position: absolute;
}

.styledScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d3d3;
    position: absolute;
}

.favorite-active-btn {
    background: #ffdb2c !important;
    border-color: #ffdb2c !important;
    color: #6b6156 !important;
    box-shadow: 0px 0px 3px #1f1f1f !important;
}

.empty-dashboard {
    display: flex;
    height: 500px;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    flex-direction: column;
}

.file-upload-area {
    margin-top: 20px;
    box-shadow: 0px 3px 5px -1px #aeaeae;
    border-radius: 16px;
    overflow: hidden;
    transition: all 300ms ease;
    width: 250px;
    cursor: pointer;
}

.file-upload-area.area-blue {
    background: #84b8e1;
}

.file-upload-area.area-orange {
    background: #ff683836;
}

.file-upload-area.area-green {
    background: #93e78d
}

.file-upload-area:hover {
    filter: brightness(1.08);
    box-shadow: 0px 5px 5px -1px #aeaeae;
}

.file-upload-button {
    flex-direction: column;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.area-blue .file-upload-button-text {
    color: #ffffff;
    border-top: 2px solid #437195;
    background: #22609094;
}

.area-orange .file-upload-button-text {
    color: #5e5e5e;
    border-top: 2px solid #d5a7a7;
    background: #ff683836;
}

.area-green .file-upload-button-text {
    color: #fff;
    border-top: 2px solid #4c9d46;
    background: #3e89397a;
}

.file-upload-button-text {
    width: 100%;
    height: 35px;
    border-top: 2px solid #d5a7a7;
    font-size: 14px;
    padding: 8px 5px;
    height: 45px;
    font-weight: bold;
}

.empty-dashboard div {
    text-align: center;
    line-height: 2;
}

.empty-dashboard .text {
    color: #434144c4;
    font-size: 23px;
}

.content-description {
    width: 100%;
    display: block;
    text-align: left;
    color: #a2a2a2;
    padding-left: 20px;
    margin: 10px 0;
}

.ant-form-item {
    margin-bottom: 10px;
}

::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #fff;
    padding: 2px 1px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    position: absolute;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #d3d3d3;
    position: absolute;
}

.copyright {
    width: 100%;
    text-align: center;
    border-top: 1px solid #f1f1f1;
    background: #fff;
    padding: 5px 0;
    color: #23608fa1;
    font-size: small;
}

.rag-green {
    background-color: lightgreen;
}

.rag-amber {
    background-color: lightsalmon;
}

.rag-red {
    background-color: lightcoral;
}

.dot {
    stroke: #000;
}

.axis path,
.axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

.label {
    fill: #777;
}

.year.label {
    font: 500 196px "Helvetica Neue";
    fill: #ddd;
}

.year.label.active {
    fill: #aaa;
}

.overlay {
    fill: none;
    pointer-events: all;
    cursor: ew-resize;
}

.soft-gray-bg {
    background: rgb(251, 251, 251);
}

.dataAccordionHeader {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.data-divider::before,
.data-divider::after {
    border-color: #bbb !important;
}

.data-divider span {
    padding: 0 18px !important;
    color: #225f8f !important;
}

.overflow-y-scroll {
    overflow-x: hidden;
    overflow: auto;
    max-height: 500px;
}

.shadowed-area {
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.droppableArea {
    border: 2px solid #ff000000
}

.onDragDroppableStyle {
    border: 2px dashed #b7b7b7;
    background: #b1c1ce24;
}

.onDragDroppableInvalid {
    border: 2px dashed #f7f73e;
    background: #f9ffc3;
}

.warning-description-text {
    display: block;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    background: #ec962a;
    color: #fff;
    border-radius: 5px;
    border-bottom: 2px solid #d07a0e;
}

.custom-switch-styling {
    border-radius: 18px;
    font-size: 12px;
}

.custom-switch-styling span {
    font-size: 12px;
}

.custom-switch-styling .switch-handle {
    border-radius: 0;
    box-shadow: 0px 0px 2px 1px #7d7d7dd6;
}

.container-filter-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    cursor: pointer;
}

.container-filter-background {
    opacity: 0.75;
    background: black;
    top: auto;
    left: 0;
    z-index: 1001;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
}

.container-filter-content {
    margin: auto;
    z-index: 1001;
    text-align: center;
}

.container-filter-text {
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    text-shadow: 0px 0px 1px black;
    max-width: 60%;
    margin: auto;
    cursor: pointer;
}

.smaller-text {
    font-size: 11px;
    color: red;
}

.background-blue {
    background: #274869;
    top: auto;
}

.small-height {
    top: auto;
}

.background-green {
    background: #628c44
}

.checkbox-style-fix {
    margin-top: 12px;
}

.checkbox-style-fix label {
    margin-bottom: 6px;
}

.checkbox-style-fix label span:last-child {
    top: -6px;
    position: relative;
}

.breadcrumb-item {
    color: #000305;
    cursor: pointer;
}

.breadcrumb-last-item {
    color: #787979;
    cursor: default;
}

.breadcrumb-item:hover {
    color: #4d8fc7;
}

.ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px
}

.icon-in-text {
    font-size: 20px;
    position: relative;
    top: -5px;
    padding: 5px;
    border: 1px solid;
    border-radius: 50%;
    margin: 0px 5px;
    transition: .3s;
}

.icon-in-text:hover {
    font-size: 20px;
    position: relative;
    top: -5px;
    padding: 5px;
    border: 1px solid;
    border-radius: 50%;
    margin: 0px 5px;
    color: #4b85b2;
    transition: .3s;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
    margin-left: 0;
}

.draggableSwitch {
    float: right;
    margin-left: 5px;
    font-size: 15px;
    position: relative;
    border: 1px solid #dadada;
    padding: 9px;
    border-radius: 20px;
    top: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-switch-checked {
    background-color: #4e7da2;
}

.apexcharts-toolbar {
    z-index: 2 !important;
}

.panel-style {
    font-size: 17px;
    position: absolute;
    z-index: 1;
    right: 0;
    border: 2px solid;
    width: 28px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    color: #000;
    opacity: .75;
}

.panel-style:hover {
    opacity: 1;
}

.cell-wrap-text {
    white-space: normal !important;
}

.ag-cell-value {
    width: 100%;
}

.ag-cell-value a {
    color: rgb(76, 175, 80)
}

.ag-cell-value a:hover {
    color: rgb(56, 142, 60);
    text-decoration: underline;
}

.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell {
    line-height: 20px !important;
}

#preventCollisionButton.ant-switch[aria-checked="false"] {
    background-color: rgb(132, 196, 88);
}

#styledExcelSwitch.ant-switch[aria-checked="false"] {
    background-color: rgb(132, 196, 88);
}

.empty-dashboard-label {
    height: 120px;
    border: 1px solid #e6e4e4;
    border-radius: 10px;
    margin: auto;
    display: flex;
    background: #fbfbfb;
    margin-bottom: 30px;
}

.empty-dashboard-label span {
    margin: auto;
    font-size: 20px;
    color: #adadad;
}

.navigation-history-table .ant-table-tbody td {
    width: 100%;;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vega-embed {
    z-index: 0;
}

.mobile-menu {
    display: none;
    position: relative;
    width: 50%;
}

.mobile-menu .mobile-menu-button {
    float: right;
    width: 40px;
    height: 40px;
    font-size: 21px;
    border-radius: 50%;
    margin-left: 5px;
}

.mobile-menu .mobile-menu-button .button-icon {
    display: flex;
}

.mobile-menu .mobile-menu-item {
    display: block;
    color: #5f5f5f;
    margin: 3px 0;
}

.mobile-menu .mobile-menu-content {
    padding: 10px 20px;
    width: 150px;
    top: 0px;
    background-color: rgb(255, 255, 255);
    color: initial;
    right: 45px;
    border: 1px solid #00000033;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.13), 0 2px 6px 2px rgba(60, 64, 67, 0);
    position: absolute;
    z-index: 1000;
}

.navbar .top-menu-search {
    width: 100%;
}

.navbar .top-menu-search .back-icon {
    display: none;
}

#vispeahenImage {
    padding: 0;
}

#vispeahenImage img {
    max-width: 200px;
    max-height: 40px;
}

.show-on-mobile {
    display: none;
}

.goDashboard {
    position: absolute;
    z-index: 2;
    width: 120px;
    top: 50%;
    left: calc(50% - 60px);
}

#labelButton, #playlistButton, #newDashboardButton, #newReportButton {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px !important;
    margin-left: 5px;
    font-size: 15px;
}

#labelButton .label-icon, #playlistButton .playlist-icon {
    font-size: 18px;
    margin-right: 4px;
}

#labelButton .label-text, #playlistButton .playlist-text {
    margin-left: 2px;
}

.mobile-menu-button .popupContainer {
    width: 250px !important;
    right: -80px !important;
    left: auto !important;
    top: 45px !important;
}

.dashboard-setting .left-tools {
    order: 1;
    min-width: 40%;
    display: flex;
    align-items: center;
    justify-content: left;
}


.dashboard-setting .middle-tools {
    order: 2;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dashboard-setting .right-tools {
    order: 3;
    min-width: 40%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row-reverse;
}

.dashboard-setting .new-button {
    font-size: 15px;
    height: 40px;
    padding: 7px 15px !important;
    margin-left: 0 !important;
    margin-right: 5px !important;
}

.export-operations {
    display: inline-block;
    position: relative;
}

.export-operations .export-toggle-button {
    font-size: 16px;
    height: 40px;
    padding: 7px 15px !important;
    margin-left: 0 !important;
}

.export-operations .export-list {
    position: absolute;
    left: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 3px -1px #9c9c9c;
    z-index: 2;
    top: 43px;
    width: 150px;
}

.export-operations .export-list-group {
    border-bottom: 1px solid gainsboro;
    padding: 5px 10px;
    display: flex;
    font-size: 16px;
    color: #5a5a5a;
}

.export-operations .export-list-group .export-list-item-icons {
    font-size: 19px;
    margin-right: 6px;
    top: 2px;
    position: relative;
}

.export-operations .export-list-item {
    border-bottom: 1px solid gainsboro;
    padding: 5px 10px;
    display: flex;
    font-size: 16px;
    color: #5a5a5a;
    cursor: pointer;
}

.export-operations .export-list-item:hover {
    background: #e2e2e2;
    color: #4e4e4e;
}

.export-operations .export-list-item .export-list-item-icons {
    font-size: 19px;
    margin-right: 6px;
    top: 2px;
    position: relative;
}

.navigation-filters-used-button {
    margin-left: 5px;
    font-size: 15px;
    max-width: 100%;
    height: 40px;
    text-align: center;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vega-embed.has-actions {
    width: 100%;
}

[vegalite=true] canvas {
    width: 95% !important;
}

.ant-modal-wrap {
    z-index: 1001;
}

.data-column-remove-icon {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 10px;
}

.data-column-remove-icon-drilldown {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
}

.column-properties-tab.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding: 8px 3px;
    text-align: center;

}

.ant-calendar-footer-extra {
    width: 100%;
}

.conditional-icon {
    margin-left: 5px;
    font-weight: 100
}

.drilldownable-header {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.table .top-grid .ag-center-cols-viewport {
    overflow: hidden !important;
}

.table .total-area * {
    overflow: hidden !important;
}

.table .total-area .ag-row {
    border: none !important;
}

.table .ag-paging-panel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.table button[ref="btNext"] {
    background-color: transparent;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYgMTJsNC00LTQtNCIgc3Ryb2tlPSIjN0Y4QzhEIiBmaWxsPSJub25lIi8+PC9zdmc+);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    overflow: hidden;
    text-indent: 100%;
    appearance: none;
    border: 0;
    opacity: 0.54;
    padding: 0;
}

.table button[ref="btPrevious"] {
    background-color: transparent;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkgNEw1IDhsNCA0IiBzdHJva2U9IiM3RjhDOEQiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    overflow: hidden;
    text-indent: 100%;
    appearance: none;
    border: 0;
    opacity: 0.54;
    padding: 0;
}

.login-user {
    border-radius: 60px;
    border: 1px solid #225f8f !important;
    font-size: 14px;
    width: 22px;
    height: 22px;
    text-align: center;
    padding: 0px;
    color: #225f8f !important;
    font-weight: bold;
    font-style: normal;
    cursor: default;
}

.UserMenu-popupcontainer {
    padding: 0px !important;
}

.infoUser-user-menu {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    font-size: 15px;
    margin-left: -6px;
}

.pin-button-configuration-or-data {
    position: absolute;
    top: 4px;
    right: 52px;
    border-radius: 40px;
    padding: 6px 5px;
    background-color: white;
    z-index: 4;
}

.interation-navigation-on-mobile {
    position: absolute;
    left: 0% !important;
    scale: 0.85;
}

.new-dashboard-shortcut {
    border-radius: 4px 4px 0px 0px;
    border: 0px;
    width: 100%;
    text-align: center;
}

.new-dashboard-shortcut>a,
.new-report-shortcut>a {
    display: inline;
    color: inherit;
    transition: none;
    -webkit-transition: none;
}

.new-report-shortcut {
    border-radius: 0px 0px 4px 4px;
    border: 0px;
    width: 100%;
    text-align: center;
}


.new-dashboard-shortcut:hover {
    background: #226191;
    color: #fff;
}

.new-report-shortcut:hover {
    background: #226191;
    color: #fff;
}

.new-dropdown {
    padding: 1px !important;
}

.new-Dashboard-Report-Modal .ant-modal-header {
    padding: 16px 24px 8px 24px;
}

.new-Dashboard-Report-Modal .ant-modal-body {
    padding: 17px 24px 24px 24px;
}

#noDataTitle {
    resize: none;
}

.anticon-info-circle {
    color: rgb(56, 123, 175)
}

#table-columns-sort-dropdown {
    margin-top: 8px;
    color: #676767 !important;
    cursor: pointer;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px
}

.pivot-apply-to-all {
    padding: 0px 0px !important;
}

.pivot-apply-to-all:hover {
    color: rgba(0, 0, 0, 0.65);
}

[data-mode-id='plaintext'] .suggest-widget {
    display: none !important;
}

.ant-select-dropdown-menu-item {
    text-align: left;
}

#gridLayout.not-scallable {
    transform: none !important;
}