.grid-system {
    background: "";
    background-image: "";
}

.plugin-container {
    box-shadow: rgba(103, 103, 103, 0.62) 0px 0px 3px 0px;
}

.grid-items {
    padding: 6px;
}