.box-item-component {
    position: relative;
}

.box-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
    white-space: normal;
    height: 35px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-bottom: none;
    color: dimgrey;
    background: #f3f3f3;
    font-size: 13px;
}

.box-item.dragging {
    border: 2px dashed #226191;
}

.box-item.button {
    background-color: #226191 !important;
    cursor: pointer !important;
    color: #fff;
}

.box-item.selected {
    background-color: #a3f0b0 !important;
    color: #426945;
}

.box-item.selected2 {
    background-color: rgb(252, 232, 192) !important;
    color: #585858;
}

.box-item.warning {
    background-color: rgb(252, 232, 192) !important;
    color: #585858;
}

.box-item.error {
    background-color: #ff777738 !important;
    color: #585858;
}

.box-item.disabled {
    box-shadow: none !important;
    cursor: not-allowed !important;
    
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important;
}

.box-item.disabled:hover {
    filter: brightness(1);
}

.box-item:hover  {
    filter: brightness(0.98);
}

.box-item .box-text {
    position: relative;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
}

.box-item-content {
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    margin-bottom: 9px;
    background: #f7f7f7;
    box-shadow: 0px 1px 2px 0px;
    position: relative
}

.box-item-extra {
    position: absolute;
    top: 3px;
    right: 5px;
    color: #484848;
}