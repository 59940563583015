.dashboard-navigator-menu-area {
    top: 0;
    width: 0;
    height: 0;
    padding: 0;
    z-index: 97;
    position: fixed;

    --text-color: #585858;
    --text-color-1: #58585810;
    --text-color-2: #58585820;
    --text-color-3: #585858AA;
    --text-color-4: #585858DD;
    --background-color: #FFFFFF;
    --border-color-primary: #DADADA;
    --border-color-secondary: #DADADA;

    .dashboard-navigator-open-button {
        position: absolute;
        bottom: 40px;
        width: 35px;
        height: 35px;
        background-color: var(--text-color);
        z-index: 1;
        border: 1px solid var(--border-color-secondary);
        opacity: 0.7;
        transition: all 200ms ease-in-out;
    }

    .dashboard-navigator-open-button.right {
        right: 0;
        border-right: none;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .dashboard-navigator-open-button.left {
        left: 0;
        border-left: none;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }


    .dashboard-navigator-open-button .open-icon {
        color: var(--background-color);
        font-size: 16px;
        top: 0px;
        position: relative;
        left: 9px;
    }

    .dashboard-navigator-open-button:hover {
        cursor: pointer;
        opacity: 1;
    }

    .dashboard-navigator-menu {
        background-color: var(--background-color);
        transition: all .3s ease;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        color: var(--text-color);
        border-color: var(--border-color-primary);
        border-style: solid;
        border-width: 0;

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: var(--background-color);
        }

        ::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            background-color: var(--background-color);
            position: absolute;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #00000030;
            position: absolute;
        }

        .dashboard-navigator-close-button {
            border-radius: 50%;
            height: 30px;
            width: 30px;
        }

        button {
            border-radius: 4px;
        }

        button,
        .dashboard-navigator-close-button {
            border: 1px solid var(--text-color-3);
            background-color: var(--background-color);
            color: var(--text-color-3);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        button:hover,
        button:focus,
        button:focus-visible,
        .dashboard-navigator-close-button:hover,
        .dashboard-navigator-close-button:focus,
        .dashboard-navigator-close-button:focus-visible {
            background-color: var(--text-color);
            color: var(--background-color);
        }

        .dashbaord-navigator-error {
            width: 100%;
            height: calc(100% - 48px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-top: 1px solid var(--border-color-primary);
        }

        .ant-menu {
            background: var(--background-color);
        }

        .ant-menu:not(.ant-menu-sub) {
            border: none;
            border-top: 1px solid var(--border-color-primary);
            border-bottom: 1px solid var(--border-color-primary);
        }

        .ant-menu-submenu {
            width: 100%;
            padding: 8px;
            border-radius: 4px;
            background-color: transparent;

            .ant-menu-sub {
                background-color: transparent;
            }

            .ant-menu-submenu-title {
                font-size: 14px;
                color: var(--text-color);
                font-weight: normal;
                padding: 0px !important;
                height: fit-content;
                border: none;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;

                span {
                    width: calc(100% - 16px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: normal;
                }

                .ant-menu-submenu-arrow,
                .ant-menu-submenu-arrow::before,
                .ant-menu-submenu-arrow::after {
                    right: 1px;
                    color: var(--text-color);
                    background-color: var(--text-color);
                    background-image: none;
                    -webkit-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
            }

            .ant-menu-submenu-title:hover {
                .ant-menu-submenu-arrow::before {
                    background: var(--text-color);
                }

                .ant-menu-submenu-arrow::after {
                    background: var(--text-color);
                }
            }

            .ant-menu-item {
                font-weight: normal;
                padding: 8px !important;
                border-radius: 4px;
                font-size: 14px;
                color: var(--text-color);
                justify-content: start;
                width: 100%;
                height: fit-content;
                line-height: normal;

                span {
                    width: 100%;
                    height: fit-content;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .ant-menu-item-selected,
            .ant-menu-item::after {
                border: 0
            }

            .ant-menu-item-selected {
                background-color: var(--text-color-4) !important;
                color: var(--background-color) !important;
            }

            .ant-menu-item:hover,
            .ant-menu-item:focus,
            .ant-menu-item:focus-visible,
            .ant-menu-item-active {
                background-color: var(--text-color-1);
                color: var(--text-color);
            }
        }

        .ant-menu-submenu-active,
        .ant-menu-submenu-open {
            background-color: var(--text-color-1);
        }

        .ant-menu-submenu-open {
            .ant-menu-sub {
                border-top: 1px solid var(--text-color-1);
            }

            .ant-menu-submenu-title {
                margin-bottom: 8px;
            }
        }

        .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: var(--text-color);
        }

        .ant-select-selection-selected-value {
            .dashboard-list-sort-item-label {
                display: none;
            }
        }

        .dashboard-list-sort {
            .ant-select-dropdown {
                width: fit-content !important;
                left: unset !important;
                right: 0 !important;
            }
        }

        .ant-select-selection {
            transition: all .3s ease;
            border-color: var(--border-color-primary);

            .ant-select-arrow {
                transition: all .3s ease;
                color: var(--border-color-primary);
            }
        }

        .ant-select-selection,
        .ant-select-dropdown,
        .ant-select-selection:hover,
        .ant-select-selection:focus,
        .ant-select-selection:focus-visible,
        .ant-select-selection[aria-expanded=true] {
            border-color: var(--border-color-primary);
            background-color: var(--background-color);
            color: var(--text-color);
            box-shadow: none;
        }

        .ant-select-dropdown {
            border: 1px solid var(--border-color-primary);
            padding: 4px;
        }

        .ant-select-selection:hover,
        .ant-select-selection:focus,
        .ant-select-selection:focus-visible,
        .ant-select-selection[aria-expanded=true] {
            border-color: var(--text-color);
        }

        .ant-select-selection:hover .ant-select-arrow,
        .ant-select-selection:focus .ant-select-arrow,
        .ant-select-selection:focus-visible .ant-select-arrow,
        .ant-select-selection[aria-expanded=true] .ant-select-arrow {
            color: var(--text-color);
        }

        .ant-input-affix-wrapper {
            .ant-input {
                border-color: var(--text-color-3);
                background-color: var(--background-color);
                color: var(--text-color);
                box-shadow: none;
            }

            .ant-input:hover,
            .ant-input:focus,
            .ant-input:focus-visible {
                border-color: var(--text-color);
            }

            .ant-input-suffix,
            .ant-input-prefix,
            .ant-input-clear-icon {
                color: var(--text-color-3);
            }
        }

        .ant-input-affix-wrapper:has(.ant-input:hover),
        .ant-input-affix-wrapper:has(.ant-input:focus),
        .ant-input-affix-wrapper:has(.ant-input:focus-visible) {

            .ant-input-suffix,
            .ant-input-prefix,
            .ant-input-clear-icon {
                color: var(--text-color);
            }
        }

        .dashboard-navigator-dashboard-search {
            width: 100%;
            padding: 8px;
        }

        .dashboard-navigator-error {
            width: 100%;
            height: calc(100% - 48px);
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .dashboard-navigator-error-title {
                max-width: 100%;
                word-break: break-word;
                text-align: center;
                padding: 0 8px;
                margin-bottom: 10%;
            }

            button {        
                width: fit-content;
                background-color: var(--background-color);
                border-color: var(--text-color);
                color: var(--text-color);
            }

            button:hover,
            button:focus,
            button:focus-visible {
                background-color: var(--text-color);
                color: var(--background-color);
            }
        }
    }
}

.dashboard-navigator-menu-area.left {
    padding-right: 8px;
    left: 0;

    .dashboard-navigator-menu {
        left: -250px;
    }

    .dashboard-navigator-menu.visible {
        left: 0;
        border-right-width: 1px;
    }
}

.dashboard-navigator-menu-area.right {
    padding-left: 8px;
    right: 0;

    .dashboard-navigator-menu {
        right: 0;
        border-left-width: 1px;
    }

    .dashboard-navigator-menu.visible {
        right: 250px;
        border-left-width: 1px;
    }
}

/* .dashboard-navigator-menu {
    

    .dashboard-navigator-title {
        height: 32px;
        display: flex;
        align-items: end;
        text-align: center;
    }

    .ant-select-selection {
        transition: all .3s ease;
        border-color: #58585833;
    }
    
    .ant-select-selection,
    .ant-select-dropdown,
    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:focus-visible,
    .ant-select-selection[aria-expanded=true] {
        background-color: var(--background-color);
        color: var(--text-color);
        box-shadow: none;
    }
    
    .ant-select-dropdown {
        border: 1px solid var(--text-color);
        padding: 4px;
    }
    
    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:focus-visible,
    .ant-select-selection[aria-expanded=true] {
        border-color: var(--text-color);
    }
    
    .ant-select-selection .ant-select-arrow {
        transition: all .3s ease;
        color: #58585833;
    }
    
    .ant-select-selection:hover .ant-select-arrow,
    .ant-select-selection:focus .ant-select-arrow,
    .ant-select-selection:focus-visible .ant-select-arrow,
    .ant-select-selection[aria-expanded=true] .ant-select-arrow {
        color: var(--text-color);
    }

    .dashboard-navigator-dashboard-item {
        margin-bottom: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 300ms ease-out;
        padding: 4px 8px;
        margin-left: 5px;
        font-size: 14px;
        color: var(--text-color);
        border-radius: 4px;
    }
    
    .dashboard-navigator-dashboard-item:hover,
    .dashboard-navigator-dashboard-item:focus,
    .dashboard-navigator-dashboard-item:focus-visible {
        background-color: #58585822;
        cursor: pointer;
    }
    
    .dashboard-navigator-dashboard-item.selected {
        background-color: var(--text-color);
        color: var(--background-color);
    }
    
    .dashboard-navigator-dashboard-item.selected:hover,
    .dashboard-navigator-dashboard-item.selected:focus,
    .dashboard-navigator-dashboard-item.selected:focus-visible {
        cursor: default;
    }
} */