.map-select {
    background: #f2f2f2;
    color: #606060;
    border: 1px solid;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px 10px;
    margin: 5px 0;
    transition: all 300ms ease;
    border-color: #d3d3d3;
    margin-right: 10px;
}

.map-select:hover {
    background: #4d7ba0;
    color: #fff;
    border-color: #4d7ba0;
}

.map-select input[type="file"] {
    display: none;
}

.topojson-list-header {
    font-size: 16px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 5px;
    padding: 0 5px 5px;
    font-weight: bold;
}

.topojson-list-items {
    padding: 5px 5px;
    font-size: 15px;
    border-bottom: 1px solid #fafafa;
}

.topojson-list-items:nth-child(odd) {
    background: #fafafa;

}

.map-used-dashboard-item {
    padding: 5px;
}

.map-used-dashboards {
    border: 1px solid #e9e9e9;
    margin-bottom: 15px;
    box-shadow: 0px 0px 5px -3px #767676;
    border-radius: 3px;
    max-height: 350px;
    overflow-x: auto;
    overflow-y: scroll;

}

.map-used-dashboard-item:nth-child(even) {
    background: #f9f9f9;
}

.map-used-dashboard-item a {
    color: #5062d2;
    text-decoration: underline;
}