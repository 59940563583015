.conditional-format-list {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    min-height: 400px;
    background: white;
    max-height: 435px;
    overflow-x: hidden;
    overflow-y: auto;
}

.conditional-format-list-header {
    padding: 7px 0;
    background: #7e878e;
    color: #fff;
    border-bottom: 2px solid #565b5f;
}

.rule-input {
    position: relative;
}

.rule-input .column-list {
    position: absolute;
    width: calc(100% - 6px);
    background: white;
    left: 3px;
    /* padding: 6px; */
    z-index: 1;
    /* top: 36px; */
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    box-shadow: 0px 0px 2px #dbdbdc;
    color: #3c3c3c;
    max-height: 130px;
    overflow-x: hidden;
    overflow-y: auto;
}

.rule-input .column-list .column-item {
    text-align: left;
    padding: 4px 7px;
    border-bottom: 1px solid #c9c9c9;
    cursor: pointer;
    transition: all 150ms ease;
}

.rule-input .column-list .column-item:hover {
    background: #cbd8e2;
}

.target-columns,
.conditional-format-rule {
    text-align: left;
    /* margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdc; */
}

.conditional-format-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-top: 1px solid #d6d6d6;
    box-shadow: 0px -7px 11px -6px #b1b1b1;
    z-index: 14;
    background: white;
    padding: 10px;
}

.conditional-format-list-item {
    padding: 7px 0;
}

.conditional-format-list-item:nth-child(odd) {
    background: #f7f7f7;
}

.conditional-format-list-item .item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    padding: 0 16px;
}

.conditional-format-list-button {
    border: 1px solid;
    padding: 3px;
    border-radius: 50%;
    top: -2px;
    position: relative;
}

.color-displayer {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 3px;
    border: 1px solid #d6d6d6;
}

.color-displayer-icon {
    font-size: 20px;
    position: relative;
    top: -3px;
    margin-right: 3px;
    color: #454345;
}

.color-displayer-area {
    display: inline-block;
}

.color-displayer-area:last-child {
    margin-right: 0px;
}

.rule-input-box {
    min-height: 27px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    display: block;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.8;
    list-style: none;
    padding: 2px 5px;
    margin-bottom: 3px;
}

.rule-input-box .spanColumn {
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dadce0;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    height: 20px;
    line-height: 20px;
    margin: 2px 0;
    padding-left: 8px;
    padding-right: 24px;
    position: relative;
    font-weight: bold;
    font-size: 13px;
    max-width: 83%;
}

.rule-input-box .spanColumn .columnName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

.rule-input-box .spanColumn .remove {
    position: absolute;
    right: 7px;
    font-size: 17px;
    top: -2px;
    color: #ef7777;
    cursor: pointer;
}

.rule-input-box br {
    display: none;
}

.rule-input-disabled {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: calc(100% - 3px);
    background: #ffffffad;
    border-radius: 6px;
    text-align: center;
    display: flex;
}

.rule-input-disabled span{
    margin: auto;
    color: #4999de;
    text-shadow: 0px 0px 7px #ffffff;
    font-weight: bold;
}

.tooltip-for-rule .tooltiptext-for-rule {
    visibility: hidden;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 155%;
    left: 50%;
}

.tooltip-for-rule:hover .tooltiptext-for-rule {
    visibility: visible;
    opacity: .95!important;
}

.tooltip-for-rule {
    position: relative;
    display: inline-block;
    padding: 0px;
    color: rgba(0, 0, 0, 0.65);
    position: initial;
}

.tooltip-for-rule::before {
    background: white;
}

.tooltiptext-for-rule {
    background-color: #3c3c3c;
    color: #fff;
    padding: 6px 8px;
    width: auto;
    margin-left: -50%;
    font-size: 14px;
    opacity: 0;
    font-weight: normal;
}

.tooltip-for-rule .tooltiptext-for-rule::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3c3c3c transparent transparent transparent;
  }
