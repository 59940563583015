.plugin-container {
    width: 100%;
    border: 1px solid #ffffff00;
    border-radius: 5px;
    padding: 0;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px 0px #6767679e;
    -webkit-touch-callout: none !important;
}

.vega-embed summary {
    display: none;
}

.plugin-container * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.plugin-container .holder {
    margin: auto;
    width: 100%;
    height: 100%;
}

.plugin-tools {
    transition: all 300ms ease;
    opacity: 0;
    position: absolute;
    top: 4px;
    right: 3px;
    z-index: 3;
}

.plugin-container:hover .plugin-tools {
    opacity: 1;
}

.opacity-true {
    opacity: 1 !important;
}

.circle-icon {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin-right: 3px;
    border-radius: 50%;
    border: 1px solid #bbbbbb;
    color: #7b7a7a;
    box-shadow: 0px 0px 1px #757575;
    background: #fff;
}

.interaction-buttons {
    cursor: pointer;
    background: none;
    color: white;
    z-index: 3;
}

.circle-icon:hover {
    border-color: #236090 !important;
    color: #236090;
    box-shadow: 0px 5px 2px -4px #989898;
}

.plugin-title {
    margin-bottom: 10px;
    padding: 2px 0px;
    font-size: 16px;
    color: #484848;
    display: block;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.plugin-title-input {
    border: none;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 0;
    height: 29px;
    padding: 2px 5px;
}

.filter-title-h1 {
    -webkit-line-clamp: 1 !important;
}

.filter-title-h2 {
    -webkit-line-clamp: 2 !important;
}

.plugin-title-input:focus,
.plugin-title-input:hover,
.plugin-title-input:active {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #4d9ddc;
}

.fullOpacity {
    opacity: 1 !important;
    z-index: 3;
}

.config-divider-with-desc {
    color: #225f8f !important;
    margin: 0 !important;
}

.config-divider {
    color: #225f8f !important;
    margin: 25px 0 !important;
}

.drilldown-divider {
    color: #225f8f !important;
    margin: 0px 0 !important;
}

.ant-checkbox-checked::after {
    border-color: rgba(0, 0, 0, 0) !important;
}

.interaction-arrow-up {
    background-color: #ec0505;
    color: #ffffff;
}

.interaction-arrow-down {
    background-color: #30e018;
    color: #ffffff;
}

#close-button {
    font-size: 20px !important;
}

#close-button-width {
    font-size: 20px !important;
}

.interaction-close-icon {
    cursor: pointer;
    font-size: 20px !important;
    padding: 3px !important;
    background: rgba(255, 255, 255, 0.733);
    color: rgb(65, 109, 142) !important;
    font-size: 25px;
    text-align: center;
}

.interaction-check-icon {
    position: relative;
    top: -5px;
    margin-right: 8px;
    font-size: 24px;
    color: white;
    background: #359424;
    border-radius: 60px;
    padding: 4px;
}

.interaction-error-icon {
    position: relative;
    top: -5px;
    margin-right: 8px;
    font-size: 24px;
    color: white;
    background: rgb(255, 87, 34);
    border-radius: 60px;
    padding: 4px;
}

.interaction-edit-columns-area {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(177, 167, 167);
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 110px;
    padding: 0 8px;
}

.interaction-edit-columns-area .ant-checkbox-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.pivot-table table.pvtTable {
    height: initial !important;
}

.ag-center-cols-clipper {
    overflow: visible !important;
}

/* For disable to unnecessary scroll */
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
    padding: 0;
}

.apexcharts-ellipsis-text {
    width: 200px;
}

.apexcharts-ellipsis-text-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 500px;
}

.apexcharts-ellipsis-text-value {
    display: inline-block;
}

.apexcharts-ellipsis-y {
    display: flex;
}

.plugin-loader {
    position: absolute;
    border-radius: 5px;
    margin: auto;
    width: 100%;
    height: 100%;
    background: #ffffffb5;
    z-index: 10;
    display: flex;
}

.plugin-loader .spin-area {
    margin: auto
}

.plugin-error-area {
    position: fixed;
    bottom: 8px;
    left: 14px;
    color: #ff4a4aba;
    font-size: 25px;
    cursor: pointer;
    z-index: 3;
}

.interaction-navigation-list {
    position: fixed;
}

@keyframes openShowPluginToolsAnimation {
    0% {
        right: 0;
        position: relative;
        opacity: 1 !important;
    }

    99% {
        right: -120%;
        position: relative;
        opacity: 1 !important;
    }
}


@keyframes closeShowPluginToolsAnimation {
    0% {
        right: -120%;
        position: relative
    }

    99% {
        right: 0;
        position: relative
    }
}

@keyframes closeShowPluginToolsOpacityTrue {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 1;
    }
}

.showTooltipButton-open {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: right;
    padding-right: 30px;
}

.showTooltipButton-close {
    width: 35px;
    height: 30px;
    overflow: hidden;
    position: relative;
    text-align: right;
    padding-right: 30px;
    animation-name: closeShowtooltipAnimation;
    animation-duration: .5s;
}

@keyframes closeShowtooltipAnimation {
    0% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    100% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

.plugin-width-one-buttons {
    font-size: 4px;
    margin-bottom: -7px;
}

.plugin-height-one-buttons {
    font-size: 9px;
    margin-bottom: -7px;
}

.delete-vertical {
    display: none;
}

.plugin-height-one-open {
    height: 51px;
    right: 0px;
}

.plugin-height-one-close {
    height: 30px;
    right: 0px;
    animation-name: closeShowtooltipAnimation-oneHeight;
}

@keyframes closeShowtooltipAnimation-oneHeight {
    0% {
        width: 100%;
        height: 51px;
    }

    100% {
        width: 100%;
        height: 51px;
    }
}


.plugin-width-one-open {
    height: 75px;
    width: 135%;
    right: 17px;
    margin-right: 20px;
    padding-right: 10px
}

.plugin-width-one-close {
    height: 30px;
    width: 20px;
    right: -3px;
    padding-right: 10px;
    animation-name: closeShowtooltipAnimation-oneWidth;
    animation-duration: .5s;
}

@keyframes closeShowtooltipAnimation-oneWidth {
    0% {
        width: 135%;
        height: 75px;
        padding-right: 10px;
        right: 17px;
        margin-right: 20px;
    }

    100% {
        width: 135%;
        height: 75px;
        padding-right: 10px;
        right: 17px;
        margin-right: 20px;
    }
}

.configuration-buttons-one-height {
    margin-right: 2px
}

.plugins-search-area {
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    z-index: 3;
    padding-top: 10px;
    padding-bottom: 5px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-top: -15px;
    top: -15px;
    left: 0;
}

.data-area-overflow {
    min-height: 395px;
    height: max-content;
    overflow-x: hidden;
    overflow-y: auto;
}

.table-what-if-input-area {
    position: relative;
    width: 100%;
}

.table-what-if-input-area .table-what-if-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid;
    border-color: #ababab;
    padding: 0 5px;
    height: 23px;
    font-size: 12px;
    background: white;
}

.table-what-if-input-area .what-if-set-default {
    position: absolute;
    top: calc(50% - 9px);
    left: -4px;
    width: 18px;
    height: 18px;
    font-size: 9px;
    background: #92acc5;
    display: flex;
    padding: 1px;
    border-radius: 50%;
    border: 1px solid;
    color: #fff;
    cursor: pointer;
}

.table .ag-cell-edit-input {
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    padding: 0 5px;
    width: 95%;
    margin-left: 2.5%;
    height: 86%;
    box-shadow: 0px 0px 5px 2px #dcdcdc;
}

.upload-button {
    background: #f2f2f2;
    color: #606060;
    border: 1px solid;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px 10px;
    margin: 5px 0;
    transition: all 300ms ease;
    border-color: #d3d3d3;
    margin-right: 10px;
    display: inline-block;
    width: 23%;
    text-align: center;
}

.upload-button:hover {
    background: #4d7ba0;
    color: #fff;
    border-color: #4d7ba0;
}

.upload-button input[type="file"] {
    display: none;
}

.upload-image-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 72%;
    top: 8px;
    position: relative;
}

.plugin-error-area-holder {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
}

.error-blur-area {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
}

.error-plugin-image {
    z-index: 1;
    margin: auto;
    width: 90%;
    height: 80%;
    text-align: center;
    display: flex;
}

.error-plugin-image img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.react-grid-item>.react-resizable-handle {
    z-index: 3;
}

[title="Interactive JavaScript maps"] {
    display: none !important;
}

.number-filter-apply-button {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    max-width: calc(100% - 5px);
}

.number-filter-button {
    width: calc(100% - 5px);
    max-width: calc(100% - 5px);
    padding: 0px;
}

.number-filter-input-main-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usetextbox-container {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.usetextbox-container:hover {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
}

.usetextbox-input {
    border: none;
    height: 30px;
}

.usetextbox-input:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.container-textboxUse-between {
    width: calc(74% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-textboxUse {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    height: 30px;
}

.boxshadow {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
}

.zero-height {
    height: 32px;
}

.number-input-placeholder {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 13px;
    font-size: 14px;
}

.table-plugin-link-info {
    position: relative;
    margin-left: 2px;
    top: -2px;
    color: #226191;
    font-size: 13px;
    cursor: pointer;
}

.table-plugin-links .ant-tooltip-arrow {
    position: relative;
    top: 47px;
}

.table-plugin-links .ant-tooltip-inner {
    padding: 4px 6px;
    min-height: 20px;
    position: relative;
    top: 7px;
    font-size: 12.7px;
}

.inside-drilldown-type {
    font-size: 11px;
}

.piechart-drill-warn {
    margin-top: 10px;
    font-size: 11px;
    color: red;
}

.filter-select {
    border: 1px solid #aaa;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    width: 25%;
    height: 28px;
    padding: 0px 5px;
    margin-right: 4px;
    margin-left: 2px;
}

.display-flex {
    display: flex;
}

.filter-customized-input {
    width: calc(74% - 2px);
    border: 1px solid #aaa;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.filter-customized-input:hover {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
}

.filter-customized-input:focus {
    border: 1px solid rgb(217, 217, 217) !important;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    -webkit-box-shadow: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.filter-select option {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, .65);
}

.filter-select option:hover {
    background-color: rgba(0, 0, 0, .65);
}

.select2-result-style {
    font-size: 11px !important;
}

.select2-container .select2-selection--multiple {
    min-height: 28px;
    height: 28px;
}

.placeholder {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select2-selection__rendered {
    max-height: 28px;
}

.string-filter-input:placeholder-shown {
    text-overflow: ellipsis;
}

.string-filter-placeholder {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 13px;
    font-size: 14px;
    cursor: text;
}

.selection-tooltip {
    z-index: 1002;
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #484848;
    margin-top: 2px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.selection-tooltip-selected-item {
    padding: 5px 16px 4px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
}

.placeholder-per-item {
    margin-left: 10px;
    margin-top: 3px;
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    min-height: 20px;
}

.selection-tooltip-selected-items-list {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.filterClass:hover .selection-tooltip {
    transition: opacity 0.2s;
    visibility: visible;
    z-index: 1002;
}

.three-dot-button {
    display: inline-block !important;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.compare-three-dot {
    max-width: 50%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.measure-configuration-button {
    max-width: 100%;
    display: flex;
    border: 1px solid #00000033;
    border-radius: 0px;
    color: #7e7c7c;
    cursor: "pointer";
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
}

.measure-configuration-button.first {
    border-radius: 5px 0px 0px 5px
}

.measure-configuration-button.last {
    border-radius: 0px 5px 5px 0px
}

.measure-configuration-button.active {
    max-width: 100%;
    display: flex;
    border: 1px solid #00000033;
    border-radius: 0px;
    background: #4e7da2;
    color: white;
    transition: .3s;
}

.measure-configuration-button.first.active {
    border-radius: 5px 0px 0px 5px;
    background: #4e7da2;
    color: white
}

.measure-configuration-button.last.active {
    border-radius: 0px 5px 5px 0px;
    background: #4e7da2;
    color: white
}

.measure-pagination-button {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #4e7da2;
    font-weight: bold;
    border: none;
    outline: none;
    background: none;
}

.measure-pagination-button:focus {
    border: none;
    outline: none;
    background: none;
}

.measure-pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 10px;
}

.measure-tile-pagination-per-button {
    box-sizing: border-box;
    width: 7px;
    height: 7px;
    border: 1px solid #4e7da2;
    background: #4e7ea25b;
    ;
    border-radius: 100%;
    cursor: pointer;
    transition: .3s;
}

.danger-with-save {
    color: rgb(255, 185, 34);
    background: white;
    font-weight: bold;
}

.danger-with-save:hover {
    font-weight: bold;
    color: white !important;
    background: rgb(255, 185, 34) !important;
}

.measure-tile-pagination-per-button.active {
    border: 1px solid #4e7da2;
    background: #4e7da2;
    ;
    transition: .3s;
}

.filterClass .selection-tooltip {
    visibility: hidden;
}

/* 
.react-grid-item:has(.filterClass:hover) {
    z-index: 1 !important;
} */

.join-list-area .ant-table-column-sorters {
    display: flex !important;
}

.join-list-area .ant-input-suffix {
    display: none !important;
}

.has-not-saved-changes {
    color: yellow !important;
}

.plugin-container #totalGridTop .ag-root-wrapper {
    border: none;
}

@media screen and (max-width: 500px) {
    .plugin-container .table .ag-center-cols-viewport {
        overflow-x: auto !important;
    }
    
}

.set-preserved-button-row {
    height: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.set-preserved-button {
    font-size: 13px;
    border: 1px solid gray;
    background: white;
    border-radius: 5px;
    color: gray;
    padding-right: 12px;
    padding-left: 12px;
    z-index: 1;
}

.set-preserved-button-row-text {
    text-align: left;
    font-size: 16px;
    color: rgb(80, 80, 80);
}