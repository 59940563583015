.palette-colour-picker {
    position: absolute;
    z-index: 2;
    padding: 10px;
    background: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 8px;
    top: 32px;
    right: 0;
}

.theme-palette .colour {
    height: 27px;
    padding-top: 25px;
    display: inline-block;
    position: relative;
}

.theme-palette .colour:hover {
    cursor: pointer;
}

.remove-colour-button {
    position: relative;
    color: rgb(249, 47, 47);
    line-height: 22px;
    border-radius: 4px;
    margin: auto auto 17px;
    background-color: rgba(255, 255, 255, 0);
    border-image: initial;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid rgb(249, 47, 47);
    width: 50%;
  }

  .remove-colour-button:hover {
    position: relative;
    color: rgb(249, 47, 47);
    line-height: 22px;
    border-radius: 4px;
    margin: auto auto 17px;
    background-color: rgba(255, 255, 255, 0);
    border-image: initial;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid rgb(249, 47, 47);
    width: 50%;
  }

  .close-colour-palette {
    color: rgb(249, 47, 47);
    width: 23px;
    height: 23px;
    float: right;
    position: absolute;
    left: 210px;
    top: -1px;
    cursor: pointer;
  }