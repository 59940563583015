.logo-animate {
  width: 250px;
  height: 120px;
  text-align: center;
  position: absolute;
  left: calc(50% - 125px);
  top: calc(50% - 60px);
  transform: scale(0.5);
}

.logo-animate .bar {

  display: inline-block;
}

.logo-animate .bar:nth-child(1) {
  transform: rotate(-77deg);
  transform-origin: 50% 100%;
  position: absolute;
  z-index: 4;
}

.logo-animate .bar:nth-child(1) .triangle-up {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 27px solid #226191;
}

.logo-animate .bar:nth-child(1) .triangle-down {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 79.2px solid #226191;
}


.logo-animate .bar:nth-child(2) {
  transform: rotate(-50deg);
  transform-origin: 50% 100%;
  position: absolute;
  margin-top: -1px;
  animation-name: openAnimateSecond;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 300ms;
  -webkit-animation-name: openAnimateSecond;
  -webkit-animation-duration: 2s;
  z-index: 3;
}

.logo-animate .bar:nth-child(2) .triangle-up {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 27px solid #85c557;
}

.logo-animate .bar:nth-child(2) .triangle-down {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 79.2px solid #85c557;
}

.logo-animate .bar:nth-child(3) {
  transform: rotate(-23deg);
  transform-origin: 50% 100%;
  position: absolute;
  margin-top: -3px;
  margin-left: 0px;
  animation-name: openAnimateThird;
  animation-duration: 2s;
  animation-delay: 300ms;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 300ms;
  -webkit-animation-name: openAnimateThird;
  -webkit-animation-duration: 2s;
  z-index: 2;
}

.logo-animate .bar:nth-child(4) {
  transform: rotate(0deg);
  transform-origin: 100% 100%;
  position: absolute;
  z-index: 1;

}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 27px solid #fff;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 79.2px solid #fff;
}

.parallelogram {
  margin: 0;
  width: 20px;
  height: 50px;
  display: inline-block;
  background: white;
  transform: skewX(-39deg) scale(0);
  transform-origin: -300% 100%;
  margin-top: 23px;
  margin-left: 14px;
  animation-name: openAnimateFourth;
  animation-duration: 2s;
  animation-delay: 300ms;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 300ms;
  -webkit-animation-name: openAnimateFourth;
  -webkit-animation-duration: 2s;
}

.parallelogram div {
  height: 100%;
  border-bottom: 10px solid transparent;
  background: #ffffff00;
  border-right: 30px solid white;
  border-bottom: 30px solid #0e0e0e00;
  box-sizing: unset;
}

.logo-animate .text {
  position: absolute;
  bottom: -32px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 30.5px;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes openAnimateSecond {
  0% {
    transform: rotate(-77deg);
  }

  50% {
    transform: rotate(-50deg);
  }

  100% {
    transform: rotate(-77deg);
  }
}

/* Standard syntax */
@keyframes openAnimateSecond {
  0% {
    transform: rotate(-77deg);
  }

  50% {
    transform: rotate(-50deg);
  }

  100% {
    transform: rotate(-77deg);
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes openAnimateThird {
  0% {
    transform: rotate(-77deg);
  }

  50% {
    transform: rotate(-23deg);
  }

  100% {
    transform: rotate(-77deg);
  }
}

/* Standard syntax */
@keyframes openAnimateThird {
  0% {
    transform: rotate(-77deg);
  }

  50% {
    transform: rotate(-23deg);
  }

  100% {
    transform: rotate(-77deg);
  }
}

@-webkit-keyframes openAnimateFourth {
  0% {
    transform: skewX(-39deg) scale(0);
    opacity: 0;
  }

  50% {
    transform: skewX(-39deg) scale(1);
    opacity: 1;
  }

  100% {
    transform: skewX(-39deg) scale(0);
    opacity: 0;
  }
}

@keyframes openAnimateFourth {
  0% {
    transform: skewX(-39deg) scale(0);
    opacity: 0;
  }

  50% {
    transform: skewX(-39deg) scale(1);
    opacity: 1;
  }

  100% {
    transform: skewX(-39deg) scale(0);
    opacity: 0;
  }
}

#splashScreenContainer {
  width: 100%;
  height: 100%;
  background: rgba(46, 46, 46, 0.5);
  position: fixed;
  z-index: 9999;
}