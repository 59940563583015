.checkbox-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
}

.checkbox-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0px;
}

.per-filter-obj {
  box-sizing: border-box;
  cursor: pointer;
  opacity: 1;
  transition: .3s;
}

.classic-checkbox p {
  margin: 0!important;
}

.per-filter-obj-active {
  box-sizing: border-box;
  cursor: pointer;
  opacity: .6;
  /* box-shadow: inset 9px -9px 2px -8px #4c9983, inset 9px -9px 12px -6px #4c9983; */
  transition: .3s;
}

.enhanced-checkbox-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}

.default-p {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.checkbox-wrapper-1 .label-style {
  display: flex;
  margin-left: 10px;
}
.checkbox-wrapper-1 [type=checkbox].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

label {
  margin-bottom: 3px;
}

.checkbox-wrapper-1 [type=checkbox].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}
.checkbox-wrapper-1 [type=checkbox].substituted:enabled:active + label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:enabled + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}
.checkbox-wrapper-1 [type=checkbox].substituted:checked + label:before {
  background-color: #3B99FC;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled:active + label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type=checkbox].substituted:focus + label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type=checkbox].substituted:focus:active + label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:focus + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type=checkbox].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled:active + label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled + label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}
.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled:active + label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled + label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper-4 * {
  width: max-content;
  box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}
.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #07f;
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #07f;
  border-color: #07f;
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

.checkbox-wrapper-23 *,
.checkbox-wrapper-23 *:after,
.checkbox-wrapper-23 *:before {
  box-sizing: border-box;
}

.checkbox-wrapper-23 {
  display: flex;
  margin-left: 10px;
}

.checkbox-wrapper-23 input {
  position: absolute;
  opacity: 0;
}
  
.checkbox-wrapper-23 input:checked + label svg path {
  stroke-dashoffset: 0;
}
  
.checkbox-wrapper-23 input:focus + label {
  transform: scale(1.03);
}

.checkbox-wrapper-23 input + label {
  display: block;
  border: 2px solid #333;
  width: var(--size);
  height: var(--size);
  border-radius: 6px;
  cursor: pointer;
  transition: all .2s ease;
}
  
.checkbox-wrapper-23 input + label:active {
  transform: scale(1.05);
  border-radius: 12px;
}
  
.checkbox-wrapper-23 input + label svg {
  pointer-events: none;
  display: block;
}

.checkbox-wrapper-23 input + label svg path {      
  fill: none;     
  stroke: #333;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
  transition: all 250ms cubic-bezier(1,0,.37,.91);
}

.checkbox-wrapper-28 {
  --size: 18px;
  position: relative;
  width: max-content;
}

.checkbox-wrapper-28 *,
.checkbox-wrapper-28 *:before,
.checkbox-wrapper-28 *:after {
  box-sizing: border-box;
}

.checkbox-wrapper-28 .promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.custom-button-container .per-filter-obj {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-28 input:checked ~ svg {
  height: calc(var(--size) * 0.6);
  -webkit-animation: draw-checkbox-28 ease-in-out 0.2s forwards;
  animation: draw-checkbox-28 ease-in-out 0.2s forwards;
}
.checkbox-wrapper-28 label:active::after {
  background-color: #e6e6e6;
}
.checkbox-wrapper-28 label {
  color: #0080d3;
  line-height: var(--size);
  cursor: pointer;
  position: relative;
}
.checkbox-wrapper-28 label:after {
  content: "";
  height: var(--size);
  width: var(--size);
  margin-right: 8px;
  float: left;
  border: 2px solid #0080d3;
  border-radius: 3px;
  transition: 0.15s all ease-out;
}
.checkbox-wrapper-28 svg {
  stroke: #0080d3;
  stroke-width: 3px;
  height: 0;
  width: calc(var(--size) * 0.6);
  position: absolute;
  left: calc(var(--size) * 0.21);
  top: calc(var(--size) * 0.2);
  stroke-dasharray: 33;
}

@-webkit-keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.checkbox-wrapper-29 {
  margin-left: 10px;
  --size: 1rem;
  --background: #fff;
  font-size: var(--size);
}

.checkbox-wrapper-29 *,
.checkbox-wrapper-29 *::after,
.checkbox-wrapper-29 *::before {
  box-sizing: border-box;
}

.checkbox-wrapper-29 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-29 .checkbox__label {
  width: var(--size);
}

.checkbox-wrapper-29 .checkbox__label:before {
  content: " ";
  display: block;
  height: var(--size);
  width: var(--size);
  position: absolute;
  top: calc(var(--size) * 0.125);
  left: 0;
  background: var(--background);
}

.checkbox-wrapper-29 .checkbox__label:after {
  content: " ";
  display: block;
  height: var(--size);
  width: var(--size);
  border: calc(var(--size) * 0.14) solid #000;
  transition: 200ms;
  position: absolute;
  top: calc(var(--size) * 0.125);
  left: 0;
  background: var(--background);
}

.checkbox-wrapper-29 .checkbox__label:after {
  transition: 100ms ease-in-out;
}

.checkbox-wrapper-29 .checkbox__input:checked ~ .checkbox__label:after {
  border-top-style: none;
  border-right-style: none;
  -ms-transform: rotate(-45deg); /* IE9 */
  transform: rotate(-45deg);
  height: calc(var(--size) * 0.5);
  border-color: green;
}

.checkbox-wrapper-29 .checkbox {
  position: relative;
  display: flex;
  cursor: pointer;
  /* Mobile Safari: */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox-wrapper-29 .checkbox__label:after:hover,
.checkbox-wrapper-29 .checkbox__label:after:active {
  border-color: green;
}

.checkbox-wrapper-29 .checkbox__label {
  margin-right: calc(var(--size) * 0.45);
}

.checkbox-wrapper-30 {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 9px;
}

.checkbox-wrapper-30 .checkbox {
  --bg: #fff;
  --brdr: #d1d6ee;
  --brdr-actv: #1e2235;
  --brdr-hovr: #bbc1e1;
  --dur: calc((var(--size, 2) / 2) * 0.6s);
  display: inline-block;
  width: calc(var(--size, 1) * 22px);
  position: relative;
}
.checkbox-wrapper-30 .checkbox:after {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}
.checkbox-wrapper-30 .checkbox > * {
  position: absolute;
}
.checkbox-wrapper-30 .checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: var(--bg);
  border-radius: calc(var(--size, 1) * 4px);
  border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
  color: var(--newBrdrClr, var(--brdr));
  outline: none;
  margin: 0;
  padding: 0;
  transition: all calc(var(--dur) / 3) linear;
}
.checkbox-wrapper-30 .checkbox input:hover,
.checkbox-wrapper-30 .checkbox input:checked {
  --newBrdr: calc(var(--size, 1) * 2);
}
.checkbox-wrapper-30 .checkbox input:hover {
  --newBrdrClr: var(--brdr-hovr);
}
.checkbox-wrapper-30 .checkbox input:checked {
  --newBrdrClr: var(--brdr-actv);
  transition-delay: calc(var(--dur) / 1.3);
}
.checkbox-wrapper-30 .checkbox input:checked + svg {
  --dashArray: 16 93;
  --dashOffset: 109;
}
.checkbox-wrapper-30 .checkbox svg {
  fill: none;
  left: 0;
  pointer-events: none;
  stroke: var(--stroke, var(--border-active));
  stroke-dasharray: var(--dashArray, 93);
  stroke-dashoffset: var(--dashOffset, 94);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  top: 0;
  transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
}
.checkbox-wrapper-30 .checkbox svg,
.checkbox-wrapper-30 .checkbox input {
  display: block;
  height: 100%;
  width: 100%;
}

.checkbox-wrapper-12 {
  position: relative;
  display: flex;
  margin-bottom: 5px;
}
.checkbox-wrapper-12 > svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}
.checkbox-wrapper-12 * {
  box-sizing: border-box;
}
.checkbox-wrapper-12 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}
.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}
.checkbox-wrapper-12 .cbx {
  width: 24px;
  height: 24px;
  top: calc(50vh - 12px);
  left: calc(50vw - 12px);
}
.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
}
.checkbox-wrapper-12 .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: url("#goo-12");
  filter: url("#goo-12");
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}
.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}
.checkbox-wrapper-12 .cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}
.checkbox-wrapper-12 .cbx input:checked + label {
  animation: splash-12 0.6s ease forwards;
}
.checkbox-wrapper-12 .cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}
@-moz-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb,
      16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb,
      -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@-webkit-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb,
      16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb,
      -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@-o-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb,
      16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb,
      -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb,
      16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb,
      -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

.checkbox-wrapper-53 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-53 {
  display: flex;
  margin-bottom: 5px;
  height: 30px;
}

.checkbox-wrapper-53 .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
}

.checkbox-wrapper-53 .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #ccc;
  border-radius: 100%;
  background: #e8e8e8;
  box-shadow: 3px 3px 5px #c5c5c5, -3px -3px 5px #ffffff;
}

.checkbox-wrapper-53 .container input:checked ~ .checkmark {
  box-shadow: inset 3px 3px 5px #c5c5c5, inset -3px -3px 5px #ffffff;
}

.checkbox-wrapper-53 .checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
}

.checkbox-wrapper-53 .container input:checked ~ .checkmark:after {
  opacity: 1;
}

.checkbox-wrapper-53 .container .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid darkgray;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
  transition: all 250ms;
}

.checkbox-wrapper-18 .round {
  position: relative;
}

.checkbox-wrapper-18 .round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: block;
}

.checkbox-wrapper-18 .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.checkbox-wrapper-18 .round input[type="checkbox"] {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked + label {
  background-color: #1d94ec;
  border-color: #1d94ec;
}

.multiple-row-wrap {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  text-align: center;
  word-break: break-word;
}

.single-row-wrap {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  text-align: center;
  word-break: break-word;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.tooltip-enhanced-button {
  position: relative;
  display: inline-block;
}

.tooltip-enhanced-button .tooltiptext-enhanced-button {
  visibility: hidden;
  max-width: 100px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -50px;
}

.tooltip-enhanced-button .tooltiptext-enhanced-button::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.tooltip-enhanced-button:hover .tooltiptext-enhanced-button {
  visibility: visible;
}