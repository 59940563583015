body {
    background: #fdfdfd;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

html, body {
    overflow: auto;
}

#root {
    height: 100%;
    overflow: auto;
}

.login-form {
    max-width: 500px;
    z-index: 1;
    position: relative;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

#table {
    width: 50vh;
    max-width: 320px;
    min-width: 200px;
    background-color: transparent;
}

.avatar {
    margin-left: 10%;
    margin-bottom: 4vh;
    vertical-align: middle;
    width: 80%;
    height: 80%;
}

.left {
    float: left;
}

.right {
    float: right;
}

.center {
    float: left;
    margin-left: 37%;
}

.register-noBackground .ant-drawer-mask {
    background-color: #ffffff00;
}

.noBackground .ant-drawer-mask {
    background-color: #ffffff00;
}

.ant-drawer-center {
    left: calc(50% - 200px);
    width: 400px;
    top: 0;
    height: 100%;
}


.ant-form {
    margin: auto;
}

.ant-drawer-content {
    background-color: rgba(239, 239, 239, 0.85);
    border-radius: 10px;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper,
.ant-drawer-content-wrapper {
    margin: 0 50px;
    min-height: 400px;
    height: initial;
    top: calc(50% - 250px);
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) !important;
    width: 400px;
}

.register-noBackground .ant-drawer-content-wrapper {
    margin: 0 50px;
    min-height: 400px;
    height: 550px !important;
    top: calc(50% - 250px);
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) !important;
    width: 400px;
}

.register-noBackground .ant-drawer-wrapper-body{
    overflow-y: hidden;
}

.login-logo {
    max-height: 170px;
    max-width: 80%;
    width: inherit;
    margin: auto auto 24px;
    height: inherit;
}


.circled-no-border,
.circled-no-border input {
    border: none;
    border-radius: 20px;
}

.login-button {
    background: #d8d8d8;
    border-bottom: 2px solid #9a9a9a;
    transition: all 300ms ease;
    color: #656565;
}

.login-button:hover {
    background: #d8d8d8;
    border-bottom: 2px solid #9a9a9a;
    color: #656565;
    filter: brightness(1.1);
}

.login-button-green {
    background: #86c655;
    border-bottom: 2px solid #226191;
    transition: all 300ms ease;
    color: #fff;
}

.login-button-green:hover {
    border-bottom: 2px solid #226191;
    background: #86c655;
    filter: brightness(1.1);
    color: #fff;
}

.login-input {
    border-bottom: 2px solid #c3c3c3;
}

.green-switcher.ant-switch-checked {
    background-color: #86c655;
}

.green-switcher.ant-switch-checked {
    background-color: #86c655;
}

#footer {
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
}

.footer-logo {
    margin: auto;
    background-position: left top;
    width: 49px;
    height: 45px;
    transform: scale(0.8);
    /* filter: grayscale(1); */
    opacity: 0.3;
}

.login-select .ant-select-selection {
    border-radius: 20px;
    border-bottom: 2px solid rgb(204, 204, 204);
}

.password-progress {
    margin-top: -10px;
    margin-bottom: 5px;
    padding: 0px 8px;
}

@media only screen and (max-width: 768px) {
    .login-background {
        background-position: center;
    }

    .ant-drawer-left .ant-drawer-content-wrapper,
    .ant-drawer-right .ant-drawer-content-wrapper,
    .ant-drawer-content-wrapper {
        width: 90% !important;
        margin: 0;
        margin-left: 5%;
    }

    .register-noBackground .ant-drawer-content-wrapper {
        width: 90% !important;
        margin: 0;
        margin-left: 5%;
    }

    .ant-drawer-center {
        left: 0;
        width: 100%;
    }
}