#homePageContainer {
  text-align: center;
  padding: 36px;
  /* border: 3px solid green; */
}

#TicTacToe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}