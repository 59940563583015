.formula-editor-modal .ant-modal-content {
    border-radius: 8px;
}

.formula-editor-modal .collapse-icon {
    transition: none;
}

.formula-editor-modal .data-table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.formula-editor-modal .box-item .box-text {
    max-width: 160px;
}

.formula-editor-modal .marginBotTop15 {
    margin-top: 0;
    margin-bottom: 0;
}

.formula-editor-modal code {
    background: #4d7fa21f !important;
    color: #4d7fa2;
}

.suggest-details-container,
.suggest-details-container div {
    height: fit-content;
    max-height: 300px;
    max-width: 500px;
}

.suggest-details-container .header {
    margin: 0;
    padding: 0;
    background-color: #f3f3f3;
}

.decorationsOverviewRuler,
.suggest-details-container .header p {
    display: none !important;
}

.monaco-editor .slider,
.monaco-editor .slider:hover {
    background-color: #d3d3d3 !important;
    border-radius: 10px !important;
}

.monaco-editor .horizontal,
.monaco-editor .horizontal .slider {
    height: 6px !important;
}

.monaco-editor .vertical,
.monaco-editor .vertical .slider {
    width: 6px !important;
}