.model-active-button {
    position: relative;
    background-color: rgb(205, 208, 203);
    border-radius: 50%;
    border: 3px solid rgb(250, 250, 250);
    width: 20px;
    box-shadow: rgb(205, 208, 203) 0px 0px 2px 0px;
    height: 20px;
    top: 4px;
    padding: 0;
    right: 8px;
}

.model-active-button:hover {
    background-color: rgb(39, 192, 57);
    background: #64a933;
    box-shadow: rgb(99, 169, 51) 0px 0px 2px 0px;
    border-color: #fff !important;
}

.model-active-button.active {
    background: #64a933 !important;
    box-shadow: rgb(99, 169, 51) 0px 0px 2px 0px !important;
    border-color: #fff !important;
}

.model-description {
    font-size: 14px;
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
    color: rgb(175, 175, 175);
    text-align: center;
    white-space: normal;
}

.models-toolsbar {
    color: #333;
    margin-right: 3px;
    text-align: center;
    background: white;
    padding: 5px 10px;
    font-size: 17px;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 1px 2px 0px #d8d8d8;
    margin-bottom: 10px;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.modelcollapse-edit{
    text-align: center;
    width: 50px;
    background : white;
    padding: 5px 7px;
    font-size: 17px;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 1px 2px 0px #d8d8d8;
    margin-bottom: 10px;
    color: #333;
    position: absolute;
    right: 3px;
}

.pin-icon {
    position: absolute;
    top: 3px;
    right: 50px;
    border-radius: 40px;
    padding: 6px 5px;
    background-color: white;
    z-index: 4;
}

.pin-icon-comment , .pin-icon-mail {
    border-radius: 40px;
    padding: 6px 5px;
    background-color: white;
    z-index: 4;
}

.pin-tooltip .ant-tooltip-arrow {
    top: -13px;
}

.pin-tooltip {
    padding-top: 0px;
    position: absolute;
}

.pin-tooltip .ant-tooltip-inner {
    position: relative;
    right: -5px;
    text-align: center;

}

.model-tables-pagination {
    position: -webkit-sticky;
    position: sticky;
    bottom: -12px;
    z-index: 1;
    width: 100%;
    left: 0;
    height: 33px;
    margin-top: 0px;
}

.data-constant-tables-pagination {
    position: absolute;
    z-index: 1;
    left: 17px;
    margin-top: 0px;
    height: 35px;
    bottom: 1px;
    padding: 6px 0;
    width: calc(50% - 32px);
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.data-constant-tables-pagination .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    background: none;
}

.data-constant-tables-pagination .anticon, .model-tables-pagination .anticon {
    top: -3px;
    position: relative;
}
