.treeItem {
    width: 100%;
    display: flex;
    align-self: center;
    margin: auto;
}

.ant-tree li .ant-tree-node-content-wrapper{
    width: calc(100% - 50px);
    background: #e7ebef;
    padding-left: 10px;
    box-shadow: 0px 3px 5px -4px #b7b7b7
}

.treeItemForLocal {
    color: red;
}

.treeItemForRealDB {
    color: rgb(30, 103, 221);
}

.treeItemForBoth {
    color: rgb(5, 131, 37);
}

.treeItemSuffix {
    display: flex;
    position: absolute;
    right: 0px;
}

.treeItemPanel {
    margin-right: 8px;
}
  
.treeItemIcons {
    color: green;
    margin-right: 3px;
}
    
.treeItemPanelIcons {
    color: green;
}
    
.fontIcon {
    min-width: 12px;
    margin-right: 8px;
}