 /*Scroll Bar Style*/
 .styledScrollBar::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
     border-radius: 12px;
     background-color: #F5F5F5;
 }
  
 .styledScrollBar::-webkit-scrollbar
 {
     width: 8px;
     background-color: #F5F5F5;
 }
  
 .styledScrollBar::-webkit-scrollbar-thumb
 {
     border-radius: 12px;
     -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
     background-color: rgb(34, 97, 145);
 }

 .not-allowed-area {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff78;
    z-index: 11;
    cursor: not-allowed;
 }