#loginPopup {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 1001;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.ldng-button {
    transition: 1s;
    margin-left: 10px!important;
    margin-top: 2px!important;
}

.button {
    transition: 1s;
}