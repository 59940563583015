.user-notification-container {
    width: 35px;
    height: 35px;
    border: 1px solid #225f8f;
    border-radius: 100px;
    margin-right: 5px;
    border-color: #225f8f;
    color: #225f8f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    background-color: white;
}

.user-notification-container:hover {
    background-color: #225f8f;
    color: white;
    transition: .3s;
}

.user-notification-popup-container {
    right: "55px!important"
}

.user-notification-popup {
    cursor: auto;
}

.notification-list-container {
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    min-height: 40vh;
    overflow-y: auto;
}

.user-notification-popup-container {
    padding: 0px;
}

.user-notification-popup-container .innerPopup {
    padding: 0px;
}

.per-notification-main-container {
    background-color: white;
    transition: .3s;
    width: 100%;
    padding: 11px 0px;
    border-bottom: 1px solid #e5e4e4;
}

.per-notification-main-container:hover {
    background-color: #EBEBEB;
    transition: .3s;
    cursor: pointer;
}

.unread {
    transition: .3s;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #0A66C2;
    background: #0A66C2;
    border-radius: 100px;
    left: 10px;
}

.notification-user {
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    cursor: pointer;
}

.notification-text-container {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    border-bottom: 1px solid #efefef;
}

.notification-message {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    margin-top: 5px;
    color: #b7b7b7;
    text-align: left;
    word-break: break-all;
}

.with-three-dot {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.without-three-dot {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.notification-inline-icon {
    font-size: 22px;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .user-notification-container {
        width: 30px;
        height: 30px;
    }

    .notification-icon {
        position: fixed !important;
        right: 110px !important;
        display: none;
    }

    .new-message-icon {
        top: -5px !important;
        right: 0px !important;
    }

    .new-message-icon.landing {
        top: -5px !important;
        right: 30px !important;
    }

    .notification-inline-icon {
        font-size: 17px;
    }

    .user-notification-popup-container.comesFromMobileMenu {
        position: fixed !important;
        top: 56px !important;
        right: 5px !important;
        max-height: 100% !important;
        overflow-y: auto !important;
    }
}


@media (min-width: 0px) and (max-width: 768px) {
    .user-notification-popup-container {
        width: 100vw !important;
        top: 0px !important;
        right: 0px !important;
        border-radius: 0px;
    }

    .notification-list-container {
        max-height: 100%;
    }

    .notification-icon {
        margin-left: 10px;
    }

    .user-notification-popup-container.comesFromMobileMenu {
        position: fixed !important;
        top: 0px !important;
        right: 0px !important;
        max-height: 100% !important;
        overflow-y: auto !important;
    }

    .notification-icon {
        position: fixed !important;
        right: 110px !important;
        display: none;
    }

    .notification-inline-icon {
        font-size: 17px!important;
    }

    .user-notification-container {
        width: 30px;
        height: 30px;
    }
}