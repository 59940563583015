.steps-content {
  padding: 15px 15px;
  margin: 15px 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  min-height: 150px;
  height: fit-content;
}

/**Sheet Styles**/
.sheet-list-container {
  background: #f7f7f7;
  height: 376px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 12px -4px #9d9d9d;
}

.sheet-list-title {
  font-size: 16px;
  font-weight: bold;
  color: #225f8f;
  padding: 5px 10px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
  margin-bottom: 0;
}

.sheet-item-container {
  height: calc(100% - 35px);
  overflow: hidden;
  overflow-y: auto;
}

.sheet-item {
  margin: 0;
  padding: 5px 10px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  color: dimgrey;
  border-bottom: 1px solid #e8e8e8;
  background: #f7f7f7;
}

.sheet-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 75%;
  top: 5px;
  position: relative;
  width: calc(100% - 80px); /* 80px = checkbox and icon width + 5px */
}

.sheet-item.disabled {
  color: #bdb3b3 !important;
  text-decoration: line-through;
}
.sheet-item.selected {
  background-color: #ddfff0 !important;
  color: #426945;
}

.sheet-item:hover {
  filter: brightness(1.05);
}

.sheet-item-edit {
  float: right;
  top: 7px;
  position: relative;
  cursor: pointer;
}

.sheet-item-edit:hover {
  filter: brightness(1.2);
}

.sheet-name-input {
  width: 65%;
  height: 28px;
  font-size: 13px;
}
/** -- Sheet Styles -- **/

/** Uplaod Table Styles **/
.upload-table {
  box-shadow: 0px 0px 12px -4px #9d9d9d;
  border-radius: 8px;
  overflow: hidden;
}

.upload-table-container {
  overflow: auto hidden;
}

.upload-table-title {
  font-size: 16px;
  font-weight: bold;
  color: #225f8f;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.upload-table-title .sub-title {
  font-size: 13px;
  color: #b2b2b2;
  display: inline-block;
  font-weight: normal;
  margin-left: 8px;
}

.upload-table-header {
  width: 100%;
  border: 1px solid #e6e6e6;
  height: 35px;
}

.upload-table-header .header-item {
  display: inline-block;
  font-weight: bold;
  border-right: 1px solid #c7c7c7;
  background: #225f8f;
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  margin: 0;
  padding: 2px 5px;
}

.upload-table-header .header-item:last-child {
  border-right: none;
}

.upload-table-header .header-item.disabled {
  background: rgb(255 215 157);
  color: #a4a4a4;
  text-decoration: line-through;
}

.upload-table-row {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  height: 30px;
}

.upload-table-row:nth-child(even) {
  background-color: #e8e8e8;
}

.upload-table-row:hover {
  background-color: #ddfff0;
}

.upload-table-cell {
  display: inline-block;
  border-right: 1px solid #c7c7c7;
  padding: 5px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.upload-table-cell:last-child {
  border-right: none;
}

.upload-table-cell.disabled {
  background: #ffcccc;
  color: #b99090;
  text-decoration: line-through;
}

.update-table-columns-edit-mode {
  background-color: white;
  position: absolute;
  bottom: -33px;
  left: 0;
  width: 100%;
  max-width: 300px;
  padding: 5px;
  border: 2px solid #225f8f;
  box-shadow: inset 0px 6px 17px -15px #8d8d8d, 0px 3px 8px -6px #1a1313;
  z-index: 1;
  min-width: 140px;
}
/** -- Uplaod Table Styles -- **/

/** File Validation **/
.file-validation-header {
  padding: 5px;
  font-weight: bold;
  border-bottom: 2px solid #125489;
  background: #225f8f;
  color: #fff;
}

.file-validation-item {
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
  transition: all 300ms ease;
}

.file-validation-item:nth-child(odd) {
  background-color: #e8e8e8;
}

.file-validation-item:last-child {
  border-bottom: none;
}

.sheet-status-label {
  color: white;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.upload-result-error {
  color: rgb(212, 80, 80);
  display: block;
  padding: 7px 20px;
  margin: 10px 11px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 0px 12px -10px inset #5e5e5e;
}

/** -- File Validation -- **/

.file-upload-status-minimized {
  position: fixed;
  /* width: 75px; */
  /* height: 75px; */
  text-align: center;
  bottom: 23px;
  left: 45px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 98;
}
