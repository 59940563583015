.accordion-component {}

.accordion-component .accordion {
    border: none;
}

.accordion-component .accordion-holder {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.accordion-component .accordion-header {
    cursor: pointer;
    border-radius: 0;
    border-bottom: 1px solid #d0d0d0;
    color: #555;
    padding-left: 10px;
    padding-right: 10px;
    background: none;
    position: relative;
    display: flex;
}

.accordion-component .accordion-collapse {}

.marginBotTop15 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.accordion-holder.marginBotTop15>.accordion-header {
    border-bottom: none;
}

.new-column-header .collapse-icon {
    display: none!important;
}

.accordion-component .collapse-icon {
    font-size: 15px;
    color: #676767;
    display: inline-block;
    transition: all 300ms ease;
    float: left;
    margin-right: 15px;
    position: relative;
}

.accordion-component .collapse-title {
    color: #555;
    display: inline-block;
    padding: 0 5px;
    transition: all 300ms ease;
    width: calc(100% - 30px);
}

.accordion-holder.marginBotTop15>.accordion-header .collapse-icon {
    transform: rotate(180deg);
    top: -3px;
}

.accordion-component .accordion-extra {
    float: right;
}