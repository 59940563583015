.guidelines-row {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 0;
  width: 100%;
  display: none;
}

.guidelines-col {
  border: 1px dashed rgb(245, 189, 136, 0.5);
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  background: transparent;
  transition: none;
  transition-property: left, top;
  /* border: 2px solid rgb(212, 212, 212) !important; */
  /* box-shadow: 1px 1px #e7f5f5a3, 1px 1px #e7f5f5a3; */
  border-radius: 7px;
  padding: 6px;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background-color: #fff !important;
  background-image: none;
  border: 2px dashed rgb(255, 183, 27) !important;
  border-radius: 7px;
  opacity: 0.8;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 8px;
  height: 8px;
  border-right: 2px solid rgba(132, 198, 224, 0.993);
  border-bottom: 2px solid rgba(132, 198, 224, 0.993);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.loadMoreArea {
  z-index: 1;
  position: fixed;
  bottom: 2%;
  width: 99%;
  text-align: center;
}

.loadMoreButton {
  font-weight: 800;
  text-align: center;
  margin: 0, auto;
  background-color: rgb(231, 109, 38);
  border-color: rgb(231, 109, 38);
}

.loadMoreButton:focus {
  font-weight: 800;
  text-align: center;
  margin: 0, auto;
  background-color: rgb(231, 109, 38);
  border-color: rgb(231, 109, 38);
}

.loadMoreButton svg {
  margin-bottom: 6px;
}

.delete-last-row {
  position: fixed;
  bottom: 25px;
  right: 10px;
  z-index: 10;
  opacity: 0.5;
  background: #0eb81e;
  color: white;
  border-color: white;
}

.delete-last-row:hover,
.delete-last-row:active,
.delete-last-row:visited,
.delete-last-row:focus {
  opacity: 0.9;
  background: #0eb81e;
  color: white;
  border-color: white;
}