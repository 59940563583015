.image-with-text-item {
    padding: 10px 0;
    z-index: 2;
}

.image-with-text-item.active {
    background: #fff;
    border: 1px solid #cacaca;
    border-bottom: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}