.multiple-more-items {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.show-multiple-items {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px!important;
}

.multiple-more-span {
    position: absolute;
    top: 5px;
    right: 35px;
}

.select2-selection--multiple {
    height: 32px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #fff0;
    border: none;
    border-radius: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    margin: 0;
    margin-top: 3px;
    margin-right: 12px;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 3px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow b {
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    left: 6px;
    top: 6px;
    position: absolute;
}

.placeholder {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    left: 0px!important;
}

.select2-search__field {
    display: none;
}

.display-true {
    display: inline-block!important;
}

div#filter-value-container:has(span.select2-container--below) {
    
    span.select2-container--open:not(.select2) {
        top: 100% !important;
    }
}

div#filter-value-container:has(span.select2-container--above) {
    
    span.select2-container--open:not(.select2) {
        bottom: 100% !important;
    }
}

div#filter-value-container[style*="width: 75%"] {
    
    span.select2-container--open:not(.select2) {
        left: 25% !important;
    }
}

div#filter-value-container[style*="width: 100%"] {
    
    span.select2-container--open:not(.select2) {
        left: 0% !important;
    }
}

div.filterClass > span.select2-container:not(.select2) {
    left: 0% !important;
}

div.filterClass:has(div[id^="inline-"] > span.select2-container--below) {

    span.select2-container--open:not(.select2) {
        top: 100% !important;
    }
}

div.filterClass:has(div[id^="inline-"] > span.select2-container--above) {

    span.select2-container--open:not(.select2) {
        bottom: 100% !important;
    }
}

div.react-grid-item:has(div.plugin-container div.filterClass span.select2-dropdown),
div.react-grid-item:has(div.plugin-container div.filterClass div.ant-select-dropdown:not(.ant-select-dropdown-hidden)),
div.react-grid-item:has(div.plugin-container div.filterClass div.ant-calendar-picker-container),
div.react-grid-item:has(div.plugin-container div.filterClass div[class*="-menu"]) {
    z-index: 2 !important;
}

div.react-grid-item:has(div.plugin-container div.popupContainer) {
    z-index: 3 !important;
}

div#gridLayout:has(div.plugin-container div.popupContainer) {
    
    div.react-grid-item:not(:has(div.plugin-container div.popupContainer)) {
        z-index: 0;
    }
}

div.filterClass div[style*="position: absolute"]:has(div.ant-select-dropdown[class*="ant-select-dropdown-placement-bottom"]),
div.filterClass div[style*="position: absolute"]:has(div.ant-calendar-picker-container[class*="ant-calendar-picker-container-placement-bottom"]) {
    left: 0 !important;
    top: 100% !important;

    div.ant-select-dropdown[class*="ant-select-dropdown-placement-bottom"],
    div.ant-calendar-picker-container[class*="ant-calendar-picker-container-placement-bottom"] {
        top: 0 !important;
        bottom: unset !important;
    }
}

div.filterClass div[style*="position: absolute"]:has(div.ant-select-dropdown[class*="ant-select-dropdown-placement-top"]),
div.filterClass div[style*="position: absolute"]:has(div.ant-calendar-picker-container[class*="ant-calendar-picker-container-placement-top"]) {
    left: 0 !important;
    top: unset !important;
    bottom: 100% !important;

    div.ant-select-dropdown[class*="ant-select-dropdown-placement-top"],
    div.ant-calendar-picker-container[class*="ant-calendar-picker-container-placement-top"] {
        top: unset !important;
        bottom: 100% !important;
    }
}


div.filterClass div[style*="position: absolute"] div.ant-select-dropdown[class*="ant-select-dropdown-placement"] {
    left: 0 !important;
}

div.filterClass div[style*="position: absolute"] div.ant-calendar-picker-container[class*="Left"] {
    right: unset !important;
    left: 0 !important;
}

div.filterClass div[style*="position: absolute"] div.ant-calendar-picker-container[class*="Right"] {
    left: unset !important;
    right: 0 !important;
}