.whatIf {
    display: inline-block;
    position: relative;
}

.whatIf-icon {
    font-size: 25px;
    transform: rotate(90deg);
    position: relative;
    left: 2px;
}

.whatIf-create-update .ant-row {
    margin-bottom: 5px;
}

.whatIf-list {
    margin-bottom: 40px;
}

.whatIf-list .ant-slider {
    margin: 3px 15px;
}

.whatIf-list .ant-slider .ant-slider-step {
    background: #225f8f;
    border-radius: 5px;
}

.whatIf-list .ant-slider .ant-slider-handle {
    border: solid 2px #84da93;
}

.parameter-row {
    border-bottom: 1px solid #ececec;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.parameter-col {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.parameter-display-name {
    margin-bottom: 14px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.small-sub-text {
    font-size: 11px;
    color: #5d84a7;
    position: absolute;
    bottom: 0;
    left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 5px);
}

.whatIf .ant-tabs-bar {
    margin-bottom: 10px;
}

.scenarios {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

.scenarios-area {
    position: absolute;
    bottom: 0px;
    left: 0;
    min-height: 20px;
    background: white;
    width: 100%;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid #ececec;
    box-shadow: 0px -2px 4px -3px #b7b7b7;
    padding: 5px 10px;
    z-index: 2;
}

.save-scenario-area {
    z-index: 3;
    width: 50%;
    right: 0;
    min-height: 25px;
    background: #fff;
    bottom: -43px;
    position: absolute;
    padding: 5px;
    border-color: #efefef;
    transition: all 300ms ease;
    border-radius: 0 0 6px 0;
}

.save-scenario-area.show-scenario {
    bottom: -1px;
    transition: all 300ms ease;
}

.what-if-button-with-parameters {
    display: flex;
    cursor: pointer;
}

.what-if-button-with-parameters .open-button {
    border-radius: 30px 0 0 30px;
}

.what-if-button-with-parameters .what-if-parameters {
    display: inline-block;
    border: 1px solid #e6e6e6;
    padding: 7px 10px;
    position: relative;
    max-width: calc(40vw - 384px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    padding-top: 9px;
    color: #708496;
    text-align: center;
}

.what-if-button-with-parameters .scenario-list-button {
    margin: 0 !important;
    border-radius: 0 30px 30px 0;
}

.scenario-list-dropdown li {
    padding: 0;
}

.scenario-list-dropdown .scenario-list-name {
    padding: 5px 5px 5px 0;
    display: inline-block;
    min-width: 60px;
    width: calc(100% - 38px);
}

.current-scenario-list-view {
    font-size: 14px;
    display: inline-block;
    margin-top: 3px;
}

.scenario-remove-icon {
    width: 23px;
    height: 23px;
    font-size: 13px;
    padding: 4px 0;
    margin: 0px 10px 0px 5px;
    position: relative;
    top: -4px;
    display: inline-block;
    cursor: pointer;
    color: #e87d7d;
    border: 1px solid #e87d7d;
    box-shadow: 0px 0px 1px #757575;
    border-radius: 50%;

}