.app {
  width: 100%;
  max-width: 640px;
  min-width: 360px;
  margin: 50px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 30px 0px;
}

@media (max-width: 1400px) {
  .app {
    max-width: 500px;
    margin: 20px auto;
  }
}

@media (max-width: 575px) {
  .app {
    margin-top: 0px;
  }
}