body.light {
    font-family: 'Roboto', sans-serif;
    color: #403f3f;
}

body.light .header-section {
    background: #fff;
}

body.light .btn-default {
    color: #474747;
}

body.light .dropdown-item {
    color: #474747;
}

body.light .btn-light {
    background-color: #dedede;
    border-color: #dedede;
    margin-left: 5px;
    color: #4c4c4c;
}

body.light .btn-light:hover,
body.light .btn-light:focus,
body.light .btn-light:hover,
body.light .btn-light:active {
    background-color: #bebebe;
    border-color: #bebebe;
    color: #fff;
}

body.light .btn-new {
    background-color: #ffffff;
    border-color: #dadada;
    margin-left: 5px;
    color: #585858;
}

body.light .btn-coloured {
    background-color: #ffffff;
    border-color: #dadada;
    margin-left: 5px;
    color: #226090;
}

body.light .btn-new:focus,
body.light .btn-new:hover,
body.light .btn-new:active {
    background-color: #236090;
    border-color: #236090;
    color: #ffffff;
    box-shadow: 0px 5px 2px -4px #989898;
}

body.light .success-button {
    background-color: #7dc14d;
    border-color: #7dc14d;
    color: #ffffff;
}

body.light .dashboard .section .label-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #dadada;
    margin-bottom: 15px;
}

body.light .dashboard .section h4 {
    display: inline-block;
    letter-spacing: 1px;
}

body.light .report .report-thumb .details .details-inner .field {
    padding: 0px 6px;
    background: none;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 3px;
    display: inline-block;
    font-size: 15px;
    text-align: right;
}

.dropdown-item {
    border-radius: 8px;
}

.dashboard-label-sort-icon .popupContainer {
    left: -100px !important;
    top: 20px !important;
    min-width: fit-content;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
    font-weight: 900;
}

.dropdown-item.active:hover,
.dropdown-item:active:hover {
    background-color: #fafafa;
    color: #474747;
}

.blue-button {
    background-color: #ffffff;
    border-color: #225f8f;
    color: #225f8f;
}

.blue-button:hover,
.blue-button:focus,
.blue-button:active {
    color: white;
    background: #225f8f;
    border-color: #225f8f;
}