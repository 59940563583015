.default-filters-area {
    bottom: 1%;
    width: 97%;
    background: #f9f9f9;
    z-index: 3;
    left: 1%;
    padding: 10px;
    border: 1px solid #cecece;
    box-shadow: 0px 1px 4px -2px #b5b5b5;
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    max-height: 200px;
    border-radius: 6px;
}

.show-default-filters {
    z-index: 2;
    position: absolute;
    bottom: 2%;
    right: 2%;
    font-size: 24px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    opacity: .7;
    transition: all 300ms ease;
}

.show-default-filters i {
    top: 3px;
    position: relative;
}

.show-default-filters:hover {
    opacity: 1;
    background: #226090;
    border-color: #226090;
    color: #fff;
}

.lsql-area {
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
    margin: 5px 0;
    resize: none !important;
}