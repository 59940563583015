.circle-button {
  background-color: #ffffff;
  border-color: #dadada;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  padding: 0 !important;
  text-align: center;
}

.circle-button .anticon {
  /* width: 100%; */
  /* bu kısım datasource da ilk panelin collapse ını etkilediği için kaldırılmıştır daha sonra kontrol edilerek düzeltilebilir */
}

.favorite-button,
.favorite-button:hover,
.favorite-button:active,
.favorite-button:focus {
  background: white;
  color: #ffcd3a;
  border-color: #ffcd3a;
}

.favorite-button.active,
.favorite-button.active:hover,
.favorite-button.active:active,
.favorite-button.active:focus {
  background: #ffcd3a;
  color: white;
  border-color: #ffcd3a;
}

.shared-button,
.shared-button:hover,
.shared-button:active,
.shared-button:focus {
  background: white;
  color: #85c557;
  border-color: #85c557;
}

.shared-button.active,
.shared-button.active:hover,
.shared-button.active:active,
.shared-button.active:focus {
  background: #85c557;
  color: white;
  border-color: #85c557;
}

.plugin-button .plugin-list {
  display: none;
  width: 100%;
  padding: 30px;
  top: 0;
  position: absolute;
  left: -20px;
  height: 150px;
}

.plugin-button:hover .plugin-list {
  display: block;
}

.datasource-button .datasource-list {
  width: 100%;
  padding: 30px;
  top: 0;
  position: absolute;
  left: -20px;
  height: 150px;
}

.general-button {
  position: relative;
  color: rgb(250, 250, 250);
  line-height: 22px;
  border-radius: 4px;
  margin: auto auto 17px;
  background-color: rgb(78, 126, 162);
  border-image: initial;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid rgb(42, 83, 113);
  width: 50%;
}

.general-button-outlined {
  background: #ffffff00;
  color: #4e7ea1;
  border-color: #2a537182;
  border: 1px solid #4e7ea1;
}

.general-button-outlined:hover,
.general-button-outlined:active,
.general-button-outlined:focus,
.general-button-outlined:visited {
  opacity: 0.8;
}

.general-button:hover,
.general-button:active,
.general-button:focus,
.general-button:visited {
  color: #fff;
  background: rgb(78, 126, 162);
  opacity: 0.8;
  border-bottom: 2px solid rgb(42, 83, 113);
}

.apply-button {
  background: #0eb81e;
  color: white;
  border-color: white;
}

.apply-button:hover,
.apply-button:active,
.apply-button:focus {
  background: white;
  color: #0eb81e;
  border-color: white;
  font-weight: bold;
}

.scroll-to-top {
  background: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 0;
  margin: auto;
  color: #8c8c8c;
  border-color: #8c8c8c;
  opacity: .6;
  z-index: 9;
  visibility: visible;
  transition: all 100ms ease;
}

.scroll-to-top:hover, .scroll-to-top:active, .scroll-to-top:focus {
  color: #fff;
  border-color: #256191;
  opacity: .6;
  background: #256191;
}

.scroll-to-top.fadeButton {
  transition: all 0ms linear;
  visibility: hidden;
  opacity: 0;
}

#dashboardSettingsButton[disabled=disabled], #dashboardSettingsButton:disabled {
  width: 42px;
  height: 42px;
  pointer-events: none;
  border-radius: 40px;
  top: -2px;
  left: -2px;
  padding: 0;
}