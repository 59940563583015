.custom-option-single-select {}

.custom-option-single-select .option-item {
    display: inline-block;
    border: 1px solid;
    padding: 5px;
    border-right: none;
    font-size: 13px;
    border-color: #d2d2d2;
    box-shadow: inset 0px -4px 7px -4px #5a5a5a
}

.custom-option-single-select .option-item:last-child {
    border-right: 1px solid;
    border-color: #d2d2d2;
}

.custom-option-single-select .option-item.default {
    background: #23af90;
    color: #fff;
}

.custom-option-single-select .option-item.unselect {
    box-shadow: inset 0px 4px 5px -5px #b9b9b9;
    background: #f5f5f5;
    color: #7d7d7d;
}

.custom-option-single-select .option-item.unselect:hover {
    color: #467922;
}